import { Component, OnInit } from '@angular/core';
import {
  AuthService,
  LoginType,
  OauthLoginType,
} from 'src/app/core/services/auth.service';
import { I18nService } from '@eng-ds/translate';
import { CookieAccepted } from '../../core/services/user.service';
import { Router } from '@angular/router';

declare const AgidWebComponents: any;
declare const Adsp: any;

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  LoginType = OauthLoginType;
  loginPanels: LoginType[] = [];
  private _cookieEnabled: CookieAccepted;

  constructor(
    private authService: AuthService,
    private i18n: I18nService,
    private router: Router
  ) {}

  get thirdPartiesCookieEnabled(): boolean {
    if (!this._cookieEnabled) {
      this._cookieEnabled =
        localStorage.getItem('tipoCookie') === CookieAccepted.All
          ? CookieAccepted.All
          : CookieAccepted.Tecnico;
    }
    return this._cookieEnabled === CookieAccepted.All;
  }

  showRegisterSUAOnlyFrontOffice(loginType: LoginType): boolean {
    return loginType === 'SUA_BASIC_AUTHENTICATION' ||
      loginType === 'SUA_SECONDO_LIVELLO'
      ? localStorage.getItem(this.authService.keyStorageAccountType) === 'FO'
      : true;
  }

  ngOnInit() {
    if (!localStorage.getItem(this.authService.keyStorageAccountType)) {
      AgidWebComponents.notifications.warning(
        this.i18n.translate('GO_LOGIN_NOT_TIPO_RUOLO_TITLE'),
        this.i18n.translate('GO_LOGIN_NOT_TIPO_RUOLO_TEXT')
      );

      this.router.navigate(['/']);
    }
    this.changeLoginPanels();
    addEventListener('adsp-auth-login-user-change', () =>
      this.changeLoginPanels()
    );
  }

  login(typeOauth: OauthLoginType = OauthLoginType.SUA, typeLogin: LoginType) {
    if (
      !this.thirdPartiesCookieEnabled &&
      [
        LoginType.SUA_BASIC_AUTHENTICATION,
        LoginType.SUA_SECONDO_LIVELLO,
      ].includes(typeLogin)
    ) {
      this.openAlertCookies();
      return;
    }
    this.authService.login(typeOauth, typeLogin);
  }

  isThirdOutOfThree(i: number): boolean {
    return i === 2 && this.loginPanels?.length === 3;
  }

  register() {
    if (!this.thirdPartiesCookieEnabled) {
      this.openAlertCookies();
      return;
    }
    this.authService.register();
  }

  openAlertCookies() {
    AgidWebComponents.notifications.warning(
      this.i18n.translate('MODAL_FORBIDDEN_TITLE'),
      this.i18n.translate('WARNING_NO_THIRD_PARTIES_COOKIES'),
      { position: 'right' }
    );
  }

  helperAction(type: LoginType) {
    switch (type) {
      case LoginType.SPID:
        this.actionSpid();
        break;
      case LoginType.CNS:
        this.actionCns();
        break;
      case LoginType.CIE:
        this.actionCie();
        break;
      default:
        this.register();
    }
  }

  private actionSpid() {
    window.open('https://www.spid.gov.it/', '_blank');
  }

  private actionCns() {
    window.open(
      'https://www.inps.it/accedere-ai-servizi/cns-carta-nazionale-dei-servizi#',
      '_blank'
    );
  }

  private actionCie() {
    window.open(
      'https://www.cartaidentita.interno.gov.it/identificazione-digitale/entra-con-cie/',
      '_blank'
    );
  }

  private changeLoginPanels() {
    this.authService
      .getLoginPanels(
        localStorage.getItem(this.authService.keyStorageAccountType)
      )
      .subscribe((loginPanels) => {
        this.loginPanels = loginPanels;
      });
  }
}
