import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiClient } from '@eng-ds/api-client';

const CACHE_SIZE = 1;

export class BaseService {
  private cache$: Map<string, Observable<any>> = new Map();

  constructor(protected api: ApiClient) {}

  protected get<T>(
    fn: Observable<any>,
    key: string,
    refresh: boolean = false
  ): Observable<T> {
    if (refresh) {
      this.delete(key);
    }
    if (!this.cache$.has(key)) {
      this.cache$.set(key, fn.pipe(shareReplay(CACHE_SIZE)));
    }

    // if (environment.logger.cache && environment.logger.cache === true) {
    //     console.log('BaseService.get -------------------------');
    //     console.log('key', key);
    //     console.log('cache maps', this.cache$);
    //     console.log('------------------------------------------');
    // }

    return this.cache$.get(key);
  }

  protected delete(key: string): void {
    if (this.cache$.has(key)) {
      this.cache$.delete(key);
    }
  }
}
