<div class="main-section w-100">
  <div class="header container">
    <agid-breadcrumb>
      <li (click)="goToHome()">
        <agid-breadcrumb-item href="javascript:;" separator>{{
          "PROFILE_BREADCRUMB_HOME" | translate
        }}</agid-breadcrumb-item>
      </li>

      <li>
        <agid-breadcrumb-item active tag="span">{{
          "PROFILE_BREADCRUMB_PROFILE" | translate
        }}</agid-breadcrumb-item>
      </li>
    </agid-breadcrumb>

    <div class="row mb-1">
      <div class="col">
        <div class="title">{{ "PROFILE_TITLE" | translate }}</div>
      </div>
    </div>
  </div>

  <div class="d-flex bg-white mb-10 shadow-lg">
    <div class="content container font-size-16">
      <div class="row">
        <div class="col-12">
          <agid-card-wrapper>
            <agid-card class="w-100" no-after rounded>
              <agid-card-body>
                <!--                aggiungi ngif solo per signup-->
                <p
                  [innerHTML]="'PROFILE_TEXT' | translate"
                  class="font-weight-normal font-size-16 mb-4"
                ></p>
                <adsp-form-profile
                  *ngIf="user"
                  [form]="form"
                  [readonlyFields]="readonlyFields"
                  [user]="user"
                  (statusForm)="_initFormStatusChange()"
                ></adsp-form-profile>
              </agid-card-body>
            </agid-card>
          </agid-card-wrapper>
        </div>
      </div>
    </div>
  </div>
</div>

<agid-modal
  [open]="showForbiddenItalianApiModal"
  position="center"
  keyboard="false"
  static-backdrop
>
  <agid-modal-header left-icon="it-warning-circle" left-icon-color="warning">{{
    "MODAL_FORBIDDEN_TITLE" | translate
  }}</agid-modal-header>

  <agid-modal-body class="p-4">
    <p>{{ "ERROR_ERROR_ITALIAN_WSO2_UNAUTHORIZED" | translate }}</p>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button size="small" outline (click)="onRedirectToHome()">
      {{ "MODAL_FORBIDDEN_BUTTONS_TO_HOME" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>
