<div class="w-100 bg-dark-white float-left login-content">
  <div class="container">
    <div class="row mt-5 mb-5 bg-white shadow">
      <div
        *ngFor="let loginType of loginPanels; let odd = odd; let i = index;"
        class="col-md-12 col-lg-6 p-5 d-flex flex-column justify-content-between"
        [class.custom-border]="odd"
        [class.custom-border-top]="i > 1"
      >
        <h5 class="spid-title font-weight-bold">
          {{ (loginType + "_TITLE") | translate }}
        </h5>

        <p class="font-size-16 font-weight-normal login-text mt-4 h-100">
          {{ (loginType + "_TEXT") | translate }}
        </p>

        <button
          type="button"
          class="btn spid-login-btn btn-block mt-5 rounded"
          (click)="login(LoginType[loginType], loginType)"
        >
          {{ ( loginType + "_BTN_TEXT") | translate }}
        </button>

        <p class="font-size-16 login-text mt-4" >
          <span
            class="font-weight-normal">{{
            (showRegisterSUAOnlyFrontOffice(loginType) && (( loginType + "_HELP_TEXT_1") | translate)) || ''
          }}
          </span>&nbsp;
          <span
            (click)="helperAction(loginType)"
            class="font-weight-bold primary-color cursor-pointer">{{
            (showRegisterSUAOnlyFrontOffice(loginType) && (( loginType + "_HELP_TEXT_2") | translate)) || ''
          }}</span>
        </p>
      </div>
    </div>
  </div>
</div>
