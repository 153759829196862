import { Component, Input } from '@angular/core';

declare const Adsp: any;

@Component({
  selector: 'adsp-modal-initial-wizard',
  templateUrl: './modal-initial-wizard.component.html',
  styleUrls: ['./modal-initial-wizard.component.scss'],
})
export class ModalInitialWizardComponent {
  @Input() showInitialWizardModal: boolean;

  currentStep = 1;

  next() {
    if (this.currentStep < 2) {
      this.currentStep += 1;
    }
  }

  prev() {
    if (this.currentStep > 1) {
      this.currentStep -= 1;
    }
  }

  ok() {
    Adsp.events.portal.emitInitialWizard(false);
  }
}
