<div class="p-5">
  <div class="row">
    <div class="col-12 mb-4">
      <span
        class="label_underline d-block pb-2 pt-1 font-size-18 color-dark-gray font-weight-bold"
      >
        {{ "TITLE_PAGE_LEGAL_NOTES" | translate }}
      </span>
    </div>
  </div>
  <div class="col-12 mb-4">
    <div *ngFor="let text of texts">
      <div [ngClass]="text.includes('<bold>') ? 'font-weight-bold' : ''">
        {{ adjustFont(text) }}
      </div>
      <br />
    </div>
  </div>
</div>
