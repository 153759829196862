import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from '../models/user';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

declare const Adsp: any;

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(
    private service: UserService,
    private oauthService: OAuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> {
    Adsp.loading.show();
    if (this.oauthService.hasValidAccessToken()) {
      return this.service.getInfoUser(false);
    }
  }
}
