<footer *ngIf="(actionsFooterService.actions$ | async).length" class="d-flex">
  <div class="actions-footer">
    <span *ngFor="let position of positions">
      <ng-container
        *ngFor="
          let action of actionsFooterService.actions$
            | async
            | appActionsFooterFilter: position
        "
        [ngTemplateOutlet]="actionTemplate"
        [ngTemplateOutletContext]="{ action: action }"
      ></ng-container>
    </span>

    <ng-template #actionTemplate let-action="action">
      <agid-button
        [class]="action.class"
        [disabled]="action.disabled | async"
        size="small"
        [color]="action.status || 'primary'"
        [status]="action.status || 'primary'"
        [outline]="action.outline"
        (click)="action.click()"
      >
        <agid-icon
          *ngIf="
            action.icon &&
            (action.icon?.position === 'left' ||
              action.icon.position === undefined)
          "
          [icon]="action?.icon?.icon || action.icon"
          [attr.svgSpritePath]="
            action?.icon?.svgSpritePath ? action.icon.svgSpritePath : null
          "
          [attr.size]="action?.icon?.size ? action.icon.size : null"
          [attr.color]="action?.icon?.color ? action.icon.color : null"
        ></agid-icon
        >{{ action.label | translate }}
        <agid-icon
          *ngIf="action.icon && action.icon.position === 'right'"
          [icon]="action?.icon?.icon || action.icon"
          [attr.svgSpritePath]="
            action?.icon?.svgSpritePath ? action.icon.svgSpritePath : null
          "
          [attr.size]="action?.icon?.size ? action.icon.size : null"
          [attr.color]="action?.icon?.color ? action.icon.color : null"
        ></agid-icon>
      </agid-button>
    </ng-template>
  </div>
</footer>
