<agid-modal [open]="showRoleModal | async" position="center" (closed)="close()">
  <agid-modal-header close-icon>
    <span class="title d-inline-flex">
      <agid-icon
        class="d-inline-flex fill-gray"
        icon="adsp-users"
        svg-sprite-path="/assets/svg/adsp_sprite.svg"
      ></agid-icon
      >&nbsp; {{ "MODAL_ROLE_TITLE" | translate }}</span
    >
  </agid-modal-header>

  <div class="row ml-0 mr-0 mt-3 mb-1 border-bottom border-top">
    <div class="col-12 p-4 gray-bg">
      <div class="font-size-16">{{ "MODAL_ROLE_ACTIVE_ROLE" | translate }}</div>
      <div class="font-weight-bold font-size-16">
        {{ user?.codiceRuolo | translate }}
      </div>
    </div>
  </div>

  <agid-modal-body>
    <div *ngIf="saveErrorMessage" class="alert alert-danger" role="alert">
      {{ saveErrorMessage }}
    </div>

    <app-form *ngIf="form" [form]="form"></app-form>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      *ngIf="form"
      class="float-right"
      size="small"
      (click)="save()"
      [disabled]="!form.valid"
      >{{ "MODAL_ROLE_BTN_SAVE" | translate }}</agid-button
    >
  </agid-modal-footer>
</agid-modal>
