import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Logger } from '@eng-ds/logger';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { SelectOption } from 'src/app/shared/form';
import { AuthenticationState } from '../models/authentication-state';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.class';
import { formatDate } from '@angular/common';
import { HttpEvent } from '@angular/common/http';

export enum CookieAccepted {
  All = 'ALL',
  Tecnico = 'TECHNICAL',
}

declare const Adsp: any;

@Injectable()
export class UserService extends BaseService {
  // subject usato per mostrare/nascondere il modale di conferma otp
  private showOtp = new Subject<boolean>();
  showOtp$ = this.showOtp.asObservable();

  private mustSetCookies = new Subject<boolean>();
  mustSetCookies$ = this.mustSetCookies.asObservable();

  // flag utile per differenziare le chiamate sulla conferma otp
  // di fatto nel caso in cui la conferma otp avvenga conseguentemente
  // al completamento del profilo la chiamata è confirmOtp
  // mentre nel caso in cui la conferma otp avvenga conseguentemente
  // al cambio del profilo la chiamata è confirmOtpProfile
  // confirmOtpForChangeProflie = false;
  private userToBeCompleted: User;

  constructor(
    private logger: Logger,
    private apiClient: ApiClient,
    private authService: AuthService
  ) {
    super(apiClient);
  }

  init() {
    this.logger.log(this, 'init');
    this._initLoginSucessListener();
    this._initLogoutListener();
    this._initUnauthorizedListener();
    this._checkUserProfile();
  }

  // emette l'evento per aprire/chiudere il modale
  emitShowOtp(show = true) {
    this.logger.log(this, 'emitShowOtp');
    this.showOtp.next(show);
  }

  // la chiamata salva l'utente in memoria
  getInfo(refresh = false): Observable<User> {
    const user$ = new Subject<User>();
    this.logger.log(this, 'getInfo');
    this.checkPostLogin().subscribe(() => {
      this.get(
        this.apiClient
          .request<User>('getUserInfo', {
            tipoRuolo: localStorage.getItem('accountType') || null,
          })
          .pipe(
            // TODO: work around sviluppo da eliminare il map
            map((user) => {
              this.logger.log(this, 'getInfo:Adsp.events.auth.emitUser', user);
              // user.statoAutenticazione = AuthenticationState.TO_BE_COMPLETED;
              // user.codiceLingua = 'en';

              // emette l'utente solo se è completo
              if (user?.statoAutenticazione === AuthenticationState.COMPLETED) {
                Adsp.events.auth.emitUser(user);
                this._handleCookies(
                  {
                    userUuid: user?.uuid,
                    dataModifica:
                      user?.dataModificaCookie &&
                      new Date(user?.dataModificaCookie),
                    tipo: user?.tipoCookie,
                    dataAccettazione:
                      user?.dataAccettazioneCookie &&
                      new Date(user.dataAccettazioneCookie),
                  },
                  true
                );
              }
              return user;
            }),
            catchError((event: HttpEvent<any>) =>
              of(this.authService.cleanLoginInfosStoredValues())
            )
          ),
        'getUserInfo',
        refresh
      ).subscribe((user: User) => {
        user$.next(user);

        if (user.statoAutenticazione !== 'TO_BE_COMPLETED')
          Adsp.events.auth.emitRoleChange(true);
      });
    });
    return user$.asObservable();
  }

  getInfoUser(registrazione: boolean): Observable<User> {
    if (
      this.userToBeCompleted?.statoAutenticazione ===
      AuthenticationState.TO_BE_COMPLETED
    ) {
      return of(this.userToBeCompleted);
    }
    if (registrazione) {
      return this.getInfo(false);
    } else {
      return this.apiClient.request<User>('getDetailUser');
    }
  }

  // salva i dati dell'utente
  confirmProfile(data: User): Observable<User> {
    this.logger.log(this, 'confirmProfile');
    return this.apiClient.request<User>('confirmProfile', data).pipe(
      tap((user) => {
        this.logger.log(this, 'confirmProfile:updateGetUserInfoCache', user);
        // aggiorna la cache dell'utente con la risposta
        this.get(of(user), 'getUserInfo', true);
      })
    );
  }

  checkPostLogin() {
    this.logger.log(this, 'confirmProfile');
    return this.apiClient.request<any>('checkPostLogin', {
      tipoRuolo: localStorage.getItem('accountType') || null,
    });
  }

  // chiamata per recuperare le info dell'utente

  // salva i dati del profilo utente
  changeProfile(data: User): Observable<User> {
    this.logger.log(this, 'changeProfile');
    return this.apiClient.request<User>('changeProfile', data).pipe(
      tap((user) => {
        this.logger.log(this, 'changeProfile:updateGetUserInfoCache', user);
        // aggiorna la cache dell'utente con la risposta
        this.get(of(user), 'getUserInfo', true);
      })
    );
  }

  // salva la notifica del profilo utente
  changeNotifiche(notifica: boolean, uuid: string): Observable<any> {
    return this.apiClient.request<User>(
      'changeNotifiche',
      null,
      { notifica },
      { uuid }
    );
  }

  // chiamata per recuperare le info dell'utente

  // ottiene il ruolo dell'utente
  getRole(): Observable<string> {
    this.logger.log(this, 'getRole');
    return this.getInfo().pipe(
      map((user: User) => {
        return user && user.codiceRuolo;
      })
    );
  }

  // scatena il ricaricamento del menu
  saveRole(roleUuid: string): Observable<any> {
    this.logger.log(this, 'saveRole');
    // return throwError(role);
    // return of(roleUuid);
    return this.apiClient.request('saveRole', { uuid: roleUuid }).pipe(
      switchMap(() => {
        this.logger.log(this, 'saveRole:refreshGetUserInfoCache');
        // evento per aggiornare il menu in base al ruolo

        Adsp.events.auth.emitRoleChange(true);
        // aggiorna la cache dell'utente con la risposta
        return this.getInfo(true);
      })
    );
  }

  // chiamata per confermare l'otp
  confirmOtp(
    otp: string,
    uuidSoggetto: string | null,
    uuidOtp: string,
    isSignup = false
  ): Observable<User> {
    this.logger.log(this, 'confirmOtp');

    const azione = isSignup ? 'REGISTRAZIONE' : 'MODIFICA_INDIRIZZO';
    return this._confirmOtpProfile(otp, uuidSoggetto, uuidOtp, azione).pipe(
      mergeMap((user) => {
        this.logger.log(this, 'confirmOtp:updateGetUserInfoCache', user);
        // aggiorna la sessione di autenticazione
        // aggiorna la cache dell'utente con la risposta
        return from(this.authService.refreshToken()).pipe(
          mergeMap(() => this.getInfo(isSignup))
        );
      })
    );
  }

  // chiamata per salvare il ruolo dell'utente
  // chiamata per ottenere i possibili ruoli da poter scegliere
  getRoles(): Observable<SelectOption<string, string>[]> {
    this.logger.log(this, 'getRoles');
    return this.get(
      this.apiClient.request<SelectOption<string, string>[]>('getRoles'),
      'getRoles',
      true
    );
  }

  // chiamata per salvare il ruolo dell'utente

  // controlla se l'utente passato è completo
  userIsCompleted(user: User): boolean {
    const value = user.statoAutenticazione === AuthenticationState.COMPLETED;
    this.logger.log(this, 'userIsCompleted', value);
    return value;
  }

  // controlla se l'utente passato non è completo
  userIsToBeCompleted(user: User): boolean {
    const value =
      user.statoAutenticazione === AuthenticationState.TO_BE_COMPLETED;
    this.logger.log(this, 'userIsToBeCompleted', value);
    return value;
  }

  // controlla se l'utente passato ha l'otp da confermare
  userIsOtpConfirm(user: User): boolean {
    const value = user?.statoAutenticazione === AuthenticationState.OTP_CONFIRM;
    this.logger.log(this, 'userIsOtpConfirm', value);
    return value;
  }

  getCookiesPreferences(userUuid: string) {
    return this.apiClient
      .request<{
        dataAccettazione: string;
        dataModifica: string;
        tipo: CookieAccepted;
        userUuid: string;
      }>('getCookiesPreferences', null, null, {
        userUuid,
      })
      .pipe(
        map((cookieRes) => {
          const dataAccettazione = new Date(cookieRes.dataAccettazione);
          dataAccettazione.setMilliseconds(0);
          const dataModifica = new Date(cookieRes.dataModifica);
          dataModifica.setMilliseconds(0);
          return this._handleCookies({
            dataAccettazione,
            dataModifica,
            userUuid: cookieRes.userUuid,
            tipo: cookieRes.tipo,
          });
        })
      );
  }

  setCookiesPreferences(tipo: CookieAccepted, data: Date) {
    return this.apiClient
      .request<CookieAccepted>(
        'setCookiesPreferences',
        null,
        { data: formatDate(data, 'yyyy-MM-ddTHH:mm:ss', 'en-US') + 'Z' },
        {
          tipo,
        }
      )
      .pipe(
        tap(() => this.saveCookiesPreferencesToLocalStorage(tipo, data, data))
      );
  }

  saveCookiesPreferencesToLocalStorage(
    cookie: CookieAccepted,
    dataAccettazioneBE?: Date,
    dataModificaBE?: Date
  ) {
    localStorage.setItem('tipoCookie', cookie);
    dataAccettazioneBE &&
      localStorage.setItem(
        'dataAccettazioneCookie',
        dataAccettazioneBE.toString()
      );
    dataModificaBE &&
      localStorage.setItem('dataModificaCookie', dataModificaBE.toString());
    if (!dataModificaBE && !dataAccettazioneBE) {
      const newDate = new Date();
      newDate.setMilliseconds(0);
      if (!localStorage.getItem('dataAccettazioneCookie')) {
        localStorage.setItem('dataAccettazioneCookie', newDate.toString());
        return;
      }
      localStorage.setItem('dataModificaCookie', newDate.toString());
    }
  }

  getCookiesPreferencesFromLocalStorage() {
    const dataAccettazione =
      localStorage.getItem('dataAccettazioneCookie') &&
      new Date(localStorage.getItem('dataAccettazioneCookie'));
    dataAccettazione?.setMilliseconds(0);
    const dataModifica =
      localStorage.getItem('dataModificaCookie') &&
      new Date(localStorage.getItem('dataModificaCookie'));
    dataModifica?.setMilliseconds(0);
    const tipoString = localStorage.getItem('tipoCookie');
    return {
      tipo:
        tipoString === CookieAccepted.All
          ? CookieAccepted.All
          : tipoString === CookieAccepted.Tecnico
          ? CookieAccepted.Tecnico
          : null,
      dataAccettazione,
      dataModifica,
    };
  }

  sendOtp(indirizzo: string, tipo: 'MAIL' | 'PEC', azione: 'REGISTRAZIONE' | 'MODIFICA_INDIRIZZO'): Observable<any> {
    return this.apiClient.request('sendOtp', {
      indirizzo,
      tipo,
      azione
    });
  }

  private _handleCookies(
    cookieBE: {
      dataAccettazione: Date;
      dataModifica: Date;
      tipo: CookieAccepted;
      userUuid: string;
    },
    openDialog = false
  ) {
    // caso utente appena registrato, cookies già settati
    if (
      !cookieBE.userUuid ||
      this.userToBeCompleted?.statoAutenticazione ===
        AuthenticationState.TO_BE_COMPLETED
    ) {
      return this.getCookiesPreferencesFromLocalStorage();
    }
    let cookiesLocalStorage = this.getCookiesPreferencesFromLocalStorage();
    const cookiesBEUnset = cookieBE?.tipo === null;
    const cookiesFEUnset = cookiesLocalStorage?.tipo === null;
    const bothUnset = cookiesBEUnset && cookiesFEUnset;
    // apro la dialog non devo fare altro
    if (bothUnset) {
      this.mustSetCookies.next(openDialog);
      return;
    } else if (cookiesBEUnset) {
      this.setCookiesPreferences(
        cookiesLocalStorage.tipo,
        cookiesLocalStorage.dataModifica || cookiesLocalStorage.dataAccettazione
      ).subscribe();
    } else if (cookiesFEUnset) {
      this.saveCookiesPreferencesToLocalStorage(
        cookieBE.tipo,
        cookieBE.dataAccettazione,
        cookieBE.dataModifica
      );
    } else {
      // gestione per cookie più recenti
      let mostRecentDateFE = (
        cookiesLocalStorage.dataModifica || cookiesLocalStorage.dataAccettazione
      ).getTime();
      let mostRecentDateBE = (
        cookieBE.dataModifica || cookieBE.dataAccettazione
      ).getTime();

      if (mostRecentDateFE > mostRecentDateBE) {
        this.setCookiesPreferences(
          cookiesLocalStorage.tipo,
          cookiesLocalStorage.dataModifica ||
            cookiesLocalStorage.dataAccettazione
        ).subscribe();
        return {
          tipo: cookieBE.tipo,
          dataModifica: cookieBE.dataModifica,
          dataAccettazione: cookieBE.dataAccettazione,
        };
      } else if (mostRecentDateBE > mostRecentDateFE) {
        this.saveCookiesPreferencesToLocalStorage(
          cookieBE.tipo,
          cookieBE.dataAccettazione,
          cookieBE.dataModifica
        );
      } else {
        return this.getCookiesPreferencesFromLocalStorage();
      }
    }
    return this.getCookiesPreferencesFromLocalStorage();
  }

  // sta in ascolto dell'evento di logout ed emette null sul canale auth topic user
  private _initLogoutListener() {
    Adsp.events.auth.logout$.subscribe(() => {
      this.logger.log(this, 'Adsp.events.auth.logout$.subscribe');
      Adsp.events.header.emitUser(null);
    });
  }

  // sta in ascolto sul topic api.unauthorized e token-not-valid
  private _initUnauthorizedListener() {
    merge(
      Adsp.events.auth.tokenNotValid$,
      Adsp.events.auth.apiUnauthorized$
    ).subscribe(() => {
      this.logger.log(
        this,
        'merge(Adsp.events.auth.tokenNotValid$,Adsp.events.auth.apiUnauthorized$)'
      );
      Adsp.events.auth.emitUser(null);
    });
  }

  // chiamata per confermare l'otp sul cambio del profilo
  private _confirmOtpProfile(
    otp: string,
    uuidSoggetto: string | null,
    uuidOtp: string,
    azione: 'MODIFICA_INDIRIZZO' | 'REGISTRAZIONE'
  ): Observable<User> {
    this.logger.log(this, 'confirmOtpProfile');
    // return throwError(otp);
    // return of({ statoAutenticazione: AuthenticationState.COMPLETED } as any);
    return this.apiClient.request<User>('confirmOtpProfile', {
      otp,
      uuidSoggetto,
      uuidOtp,
      azione
    });
  }

  // sta in ascolto dell'evento di login.success ed inizia il controllo del profilo
  private _initLoginSucessListener() {
    Adsp.events.auth.loginSuccess$.subscribe(() => {
      this.logger.log(this, 'Adsp.events.auth.loginSuccess$.subscribe');
      this._checkUserProfile();
    });
  }

  // isAdmin(): Observable<boolean> {
  //   return this.getRole().pipe(
  //     map((value) => value === ApplicationRole.ADMINISTRATOR)
  //   );

  // controllo del profilo utente
  private _checkUserProfile() {
    this.logger.log(this, '_checkProfileCompleted');
    // se i token di autenticazione sono validi
    if (this.authService.hasValidToken()) {
      // si fa la chiamata alla check info
      this.getInfo().subscribe((user) => {
        // se l'utente ha un profilo da confermare
        if (user.statoAutenticazione === AuthenticationState.TO_BE_COMPLETED) {
          this.logger.log(
            this,
            '_checkProfileCompleted',
            AuthenticationState.TO_BE_COMPLETED
          );
          // apre route bloccante per completare l'inserimento dei dati di profilo
          this.userToBeCompleted = user;
          Adsp.events.auth.emitProfileToBeCompleted(true);
          return;
        }

        // se l'utente ha un profilo che richiede conferma otp
        if (user.statoAutenticazione === AuthenticationState.OTP_CONFIRM) {
          this.logger.log(
            this,
            '_checkProfileCompleted',
            AuthenticationState.OTP_CONFIRM
          );
          // apre modale bloccante per la conferma otp
          this.emitShowOtp();
          return;
        }
      });
    }
  }

  // chiamata per confermare l'otp sul completamento del profilo
  private _confirmOtp(otp: string, uuid: string): Observable<User> {
    this.logger.log(this, 'confirmOtp');
    // return throwError(otp);
    // return of({ statoAutenticazione: AuthenticationState.COMPLETED } as any);
    return this.apiClient.request<User>('confirmOtp', {
      otp,
      uuid,
    });
  }

  getInfoUserAlso(): Observable<User> {
    this.logger.log(this, 'getInfoUserAlso');
    return this.apiClient.request<User>('getUserInfo', {
      tipoRuolo: localStorage.getItem('accountType') || null,
    });
  }

  // isAdmin(): Observable<boolean> {
  //   return this.getRole().pipe(
  //     map((value) => value === ApplicationRole.ADMINISTRATOR)
  //   );
  // }
}
