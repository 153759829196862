import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Logger} from '@eng-ds/logger';
import {I18nService} from '@eng-ds/translate';
import {BehaviorSubject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {BaseComponent} from 'src/app/core/components';
import {UserService} from 'src/app/core/services/user.service';
import {Form, TextInput, ValidationStatus} from 'src/app/shared/form';
import {ActivatedRoute} from '@angular/router';

declare const Adsp: any;

@Component({
  selector: 'adsp-modal-otp',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.scss'],
})
export class ModalOtpComponent extends BaseComponent implements OnInit {
  @Input() showOtpModal: BehaviorSubject<boolean>;
  @Input() type: 'email' | 'pec';
  @Input() fieldToEdit;
  @Input() uuid: string;
  @Output() done: EventEmitter<string> = new EventEmitter<string>();
  form: Form;
  formEdit: Form;

  saveErrorMessage: string;
  isSignup = false;
  otpConfirmed = false;
  otpSent = false;
  uuidOtp: string = null;

  constructor(
    private logger: Logger,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    protected i18n: I18nService
  ) {
    super(i18n);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.isSignup = Boolean(params.get('registration'));
    });
  }

  get value(): string {
    return this.formEdit.get(this.type).value;
  }

  ngOnInit(): void {
    this._initForm();

    this.showOtpModal.subscribe((show) => {
      if (show) {
        this.formEdit = new Form({
          header: {
            show: false,
          },
          controls: {
            [this.type]: new TextInput({
              ...this.fieldToEdit,
              ...{readonly: false, size: '12|12|12|12|12', required: true},
            }),
          },
        });
      }
    });
  }

  confirmOtp() {
    delete this.saveErrorMessage;
    this.logger.log(this, 'confirmOtp');
    Adsp.loading.show();

    this.userService
      .confirmOtp(this.form.value.otp, this.uuid, this.uuidOtp, this.isSignup)
      .pipe(
        catchError((err) => {
          this.saveErrorMessage = this._controlTranslate(err.error);
          /* this.saveErrorMessage = this.i18n.translate('OTP_CONFIRM_ERROR_TEXT'); */

          this.form.setErrors({});
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.logger.log(this, 'confirmOtp:subscribe', user);
        Adsp.loading.hide();

        // controlla se il salvataggio è andato bene
        if (this.userService.userIsCompleted(user) || (this.userService.userIsToBeCompleted(user) && this.isSignup)) {
          // nosconde l'alert di error (se presente)
          this.saveErrorMessage = null;

          // visualizza il feedback positivo e nasconde il form
          this.otpConfirmed = true;
          this.done.emit(this.value);
          return;
        }

        // controlla se la conferma è andata male
        if (this.userService.userIsOtpConfirm(user)) {
          this.saveErrorMessage = this.i18n.translate('OTP_CONFIRM_ERROR_TEXT');

          this.form.setErrors({});
          return;
        }
      });
  }

  close() {
    this.showOtpModal.next(false);
    this.otpSent = false;
    this.otpConfirmed = false;
    this.uuidOtp = null;
    this.form.reset();
    delete this.saveErrorMessage;
  }

  sendOtp() {
    delete this.saveErrorMessage;
    this.userService
      .sendOtp(
        this.value,
        this.type === 'email' ? 'MAIL' : 'PEC',
        this.isSignup ? 'REGISTRAZIONE' : 'MODIFICA_INDIRIZZO'
      )
      .pipe(
        catchError((err) => {
          this.saveErrorMessage = this._controlTranslate(err.error);
          this.form.setErrors({});
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.otpSent = true;
        this.uuidOtp = data?.uuidOtp;
      });
  }

  private _initForm() {
    this.logger.log(this, '_initForm');
    this.form = new Form({
      header: {
        show: false,
      },
      controls: {
        otp: new TextInput({
          type: 'text',
          size: '12|12|12|12|12',
          required: true,
          label: 'MODAL_OTP_FILED_LABEL',
          placeholder: 'MODAL_OTP_FILED_PLACEHOLDER',
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
      },
    });
  }
}
