<agid-modal
  [open]="showProfileModal"
  size="large"
  position="center"
  keyboard="false"
  static-backdrop
  scrollable
>
  <agid-modal-header>
    <span class="title">{{ "PROFILE_TITLE" | translate }}</span>
  </agid-modal-header>

  <agid-modal-body>
    <p
      class="font-weight-normal font-size-16 mb-4"
      [innerHTML]="'PROFILE_TEXT' | translate"
    ></p>

    <div *ngIf="saveErrorMessage" class="alert alert-danger" role="alert">
      {{ saveErrorMessage }}
    </div>
    <div *ngIf="error" class="alert alert-warning" role="alert">
      {{ logoutText }}
    </div>

    <adsp-form-profile
      *ngIf="user"
      [user]="user"
      [readonlyFields]="readonlyFields"
      [form]="form"
    ></adsp-form-profile>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button
      class="float-right"
      size="small"
      (click)="save()"
      [disabled]="!form.valid"
      >{{ "PROFILE_ACTIONS_FOOTER_SAVE" | translate }}</agid-button
    >
    <agid-button
      *ngIf="error"
      color="warning"
      class="float-right"
      size="small"
      (click)="logout()"
      >{{ "PROFILE_ACTIONS_FOOTER_LOGOUT" | translate }}</agid-button
    >
  </agid-modal-footer>
</agid-modal>
