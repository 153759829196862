<div class="row" style="padding: 1rem 0;">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-center m-auto">
          <button
            class="btn btn-lg btn-primary m-auto"
            size="small"
            nbButton
            type="button"
            status="primary"
            (click)="fileTag.click()"
          >
            {{ file ? "Cambia file" : (control.buttonPlaceholder | translate) }}
          </button>
          <input #fileTag type="file" class="inputfile" />
        </div>
      </div>
    </div>
    <div class="row mt-1 justify-content-center">
      <div
        *ngIf="file || control.placeholder"
        class="col-12 d-flex align-items-center"
      >
        <span class="m-auto">
          {{ file ? file.name : (control.placeholder | translate) }}
        </span>
      </div>
    </div>
  </div>
</div>
