import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Observable, of } from 'rxjs';
import { SelectOption } from 'src/app/shared/form';
import { BaseService } from './base-service.class';

@Injectable()
export class UtilityService extends BaseService {
  constructor(private apiClient: ApiClient) {
    super(apiClient);
  }

  getNazioni(): Observable<SelectOption<string, string>[]> {
    return this.get(
      this.apiClient.request<SelectOption<string, string>[]>('getNazioni'),
      'getNazioni'
    );
  }

  getProvince(uuid: string): Observable<SelectOption<string, string>[]> {
    if (!uuid) {
      return of([]);
    }
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getProvince',
      null,
      { uuid },
      null
    );
  }

  getComuni(uuid: string): Observable<SelectOption<string, string>[]> {
    return this.apiClient.request<SelectOption<string, string>[]>(
      'getComuni',
      null,
      null,
      { uuid }
    );
  }
}
