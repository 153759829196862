import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { EmptyRouteComponent } from './components/empty-route/empty-route.component';
import { ModalInitialWizardComponent } from './components/initial-wizard/modal-initial-wizard.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ModalOtpComponent } from './components/otp/modal-otp.component';
import { FormProfileComponent } from './components/profile/form-profile/form-profile.component';
import { ModalProfileComponent } from './components/profile/modal-profile/modal-profile.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ModalRoleComponent } from './components/role/modal-role.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { EditMailProfiloComponent } from './components/profile/form-profile/edit-mail-profilo/edit-mail-profilo.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { PrivacyPolicyPageComponent } from './components/privacy-policy-page/privacy-policy-page.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { LegalNotesPageComponent } from './components/legal-notes-page/legal-notes-page.component';
import { AccessibilityPageComponent } from './components/accessibility-page/accessibility-page.component';

registerLocaleData(localeIt);

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    ThemeModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ProfileComponent,
    ModalProfileComponent,
    FormProfileComponent,
    ModalRoleComponent,
    ModalOtpComponent,
    ModalInitialWizardComponent,
    EditMailProfiloComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyPageComponent,
    LegalNotesPageComponent,
    AccessibilityPageComponent,
  ],
  providers: [{ provide: OAuthStorage, useFactory: storageFactory }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
