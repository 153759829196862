<div class="p-5">
  <div class="row">
    <div class="col-12 mb-4">
    <span
      class="label_underline d-block pb-2 pt-1 font-size-18 color-dark-gray font-weight-bold"
    >
      {{ "TITLE_PAGE_PRIVACY_POLICY" | translate }}
    </span>
    </div>
  </div>
  <adsp-auth-privacy-policy [userUuid]="userUuid" (onChange)="this.changedValue($event)"></adsp-auth-privacy-policy>
</div>
