<div class="col-12 mb-4">
  <!-- <div translate>PRIVACY_POLICY_LONG_TEXT</div> -->
  <div *ngFor="let text of texts">
    <div [ngClass]="text.includes('<bold>') ? 'font-weight-bold' : ''">
      {{ adjustFont(text) }}
    </div>
    <br />
  </div>
</div>
<div *ngIf="form" class="row py-1 mb-4">
  <div class="col ml-4">
    <app-form [form]="form"></app-form>
  </div>
</div>
