<div class="row pt-4 w-100">
  <div class="col-12">
    <span
      class="
        label_underline
        d-block
        pb-2
        pl-3
        font-size-18
        color-dark-gray
        font-weight-bold
      "
    >
      {{ "PROFILE_FORM_" + type.toUpperCase() + "_TITLE" | translate }}
    </span>
  </div>
</div>
<div class="row mt-4" *ngIf="type === 'email' ? mail : true">
  <app-form-input
    [class]="size?.toClass() || 'col-12 p-0'"
    [field]="form.get(type)"
    [form]="form"
    name="{{ type + 'Edit' }}"
  ></app-form-input>

  <agid-button
    (click)="openOtp()"
    class="align-self-center pl-3 pt-3"
    color="primary"
    size="small"
  >
    {{ "PROFILE_FORM_EDIT_MAIL_OTP_BUTTON" | translate }}
  </agid-button>
</div>

<div class="row mt-4" *ngIf="type === 'email' ? !mail : false">
  <agid-button
    (click)="openOtp()"
    class="align-self-center pl-3 pt-3"
    color="danger"
    size="small"
  >
    {{ "PROFILE_FORM_NEW_MAIL_OTP_BUTTON" | translate }}
  </agid-button>
</div>

<adsp-modal-otp
  [uuid]="uuid"
  [showOtpModal]="showOtpModal"
  [type]="type"
  [fieldToEdit]="fieldConfig"
  (done)="onOtpDone($event)"
></adsp-modal-otp>
