<agid-modal [open]="showOtpModal | async" position="center" (closed)="close()">
  <agid-modal-header close-icon class="align-items-baseline pb-3">
    {{ "MODAL_" + type.toUpperCase() + "_OTP_TITLE" | translate }}
  </agid-modal-header>

  <div class="row ml-0 mr-0 mb-1 border-bottom border-top">
    <div *ngIf="!otpSent" class="col-12 p-4 gray-bg">
      {{ "MODAL_OTP_EMAIL_HINT" | translate }}
    </div>
    <div *ngIf="otpSent" class="col-12 p-4 gray-bg">
      <!-- messaggio otp inviato correttamente -->
      <div *ngIf="!otpConfirmed; else otpConfirmedAlert">
        <div class="font-size-16">{{ "MODAL_OTP_EMAIL_TEXT" | translate }}</div>
        <div class="font-weight-bold font-size-16">{{ value }}</div>
      </div>
      <!-- alert otp confermato correttamente -->
      <ng-template #otpConfirmedAlert>
        <span>
          <span class="check-icon rounded-icon bg-success">
            <agid-icon icon="it-check" color="white" size="small"></agid-icon>
          </span>
          <span class="font-weight-bold font-size-16 pl-3"
            >{{ "OTP_CONFIRM_SUCCESS_TITLE" | translate }}
          </span>
        </span>
      </ng-template>
    </div>
  </div>

  <agid-modal-body>
    <!-- messagiio otp confermato correttamente -->
    <p *ngIf="otpConfirmed" class="mb-4">
      {{ "OTP_CONFIRM_SUCCESS_TEXT" | translate }}
    </p>

    <div *ngIf="saveErrorMessage" class="alert alert-danger" role="alert">
      {{ saveErrorMessage }}
    </div>

    <app-form *ngIf="form && otpSent && !otpConfirmed" [form]="form"></app-form>
    <app-form *ngIf="formEdit && !otpSent" [form]="formEdit"></app-form>
  </agid-modal-body>

  <agid-modal-footer class="flex-row-reverse">
    <agid-button
      *ngIf="formEdit && !otpConfirmed"
      size="small"
      outline
      (click)="sendOtp()"
      [disabled]="!formEdit.valid"
    >
      {{
        "MODAL_OTP_BTN_SEND_OTP" + (this.otpSent ? "_AGAIN" : "") | translate
      }}
    </agid-button>
    <agid-button
      *ngIf="form && otpSent && !otpConfirmed"
      size="small"
      (click)="confirmOtp()"
      [disabled]="!form.valid"
    >
      {{ "MODAL_OTP_BTN_CONFIRM" | translate }}
    </agid-button>
    <agid-button *ngIf="otpConfirmed" size="small" (click)="close()">{{
      "MODAL_OTP_BTN_OK" | translate
    }}</agid-button>
  </agid-modal-footer>
</agid-modal>
