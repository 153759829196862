import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@eng-ds/logger';
import {
  AuthConfig,
  OAuthErrorEvent,
  OAuthService,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { merge, Observable } from 'rxjs';
import { ApiClient } from '@eng-ds/api-client';
import { map } from 'rxjs/operators';

const keyStoragePostLoginUrl = 'postLoginUrl';
const keyStorageOauthLoginType = 'oauthLoginType';
const keyStorageLoginType = 'loginType';
const keyStorageAccountType = 'accountType';

export enum OauthLoginType {
  SPID = 'oauth2Spid',
  CIE = 'oauth2Cie',
  CNS = 'oauth2Cns',
  SUA = 'oauth2Sua',
  SUA_SECONDO_LIVELLO = 'oauth2Sua',
  SUA_BASIC_AUTHENTICATION = 'oauth2Sua',
}

export enum LoginType {
  SUA_SECONDO_LIVELLO = 'SUA_SECONDO_LIVELLO',
  SUA_BASIC_AUTHENTICATION = 'SUA_BASIC_AUTHENTICATION',
  SPID = 'SPID',
  CNS = 'CNS',
  CIE = 'CIE',
}

declare const Adsp: any;

/**
 * Eventi auth
 * token not valid: lanciato quando le guardie trovano i token invalidi
 * api unauthorized: lanciato quando le api tornano 401
 * logout: lanciato da adsp-header quando l'utente clicca su Logout
 *
 * login success: lanciato da adsp-auth quando la login avviene con successo
 * login error: lanciato da adsp-auth quando la login va in errore
 *
 */

@Injectable()
export class AuthService {
  constructor(
    private logger: Logger,
    private oauthService: OAuthService,
    private apiClient: ApiClient,
    private storageService: OAuthStorage,
    private router: Router /* private userService: UserService */
  ) {
    this._initEventsOauthListeners();
    this._initLogoutListener();
    this._initUrlToStoreListener();
    /*  this._initUnauthorizedListener(); */
  }

  get keyStoragePostLoginUrl(): string {
    return keyStoragePostLoginUrl;
  }
  get keyStorageOauthLoginType(): string {
    return keyStorageOauthLoginType;
  }
  get keyStorageLoginType(): string {
    return keyStorageLoginType;
  }
  get keyStorageAccountType(): string {
    return keyStorageAccountType;
  }

  hasValidToken(): boolean {
    if (!Adsp.configs.get('auth.enabled')) {
      return true;
    }
    const valid =
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken();

    this.logger.log(this, 'hasValidToken', valid);

    return valid;
  }

  storeRedirectAppUri(url: string): void {
    this.logger.log(this, 'storeRedirectAppUri', `url: ${url}`);
    this.storageService.setItem(keyStoragePostLoginUrl, url);
  }

  removeStoredRedirectAppUri(): void {
    this.logger.log(this, 'removeStoredRedirectAppUri');
    this.storageService.removeItem(keyStoragePostLoginUrl);
  }

  storeLoginType(type: OauthLoginType, loginType: LoginType): void {
    this.logger.log(this, 'storeLoginType', `type: ${type}`);
    this.storageService.setItem(keyStorageOauthLoginType, type);
    this.storageService.setItem(keyStorageLoginType, loginType);
    localStorage.setItem(keyStorageOauthLoginType, type);
    localStorage.setItem(keyStorageLoginType, loginType);
  }

  silentRefresh(): void {
    this.oauthService.silentRefresh();
  }

  getIdentityClaims(): object {
    return this.oauthService.getIdentityClaims();
  }

  getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  loadDiscoveryDocument(): Promise<object> {
    return this.oauthService.loadDiscoveryDocument();
  }

  redirectToDefaultPostLoginRoute(): void {
    const route = Adsp.configs.get('auth.defaultPostLoginRoute');
    this.logger.log(this, 'redirectToDefaultPostLoginRoute', route);
    this.router.navigate([route]);
  }

  redirectTodefaultAdminPostLoginRoute(): void {
    const route = Adsp.configs.get('auth.defaultAdminPostLoginRoute');
    this.logger.log(this, 'redirectTodefaultAdminPostLoginRoute', route);
    this.router.navigate([route]);
  }

  redirectToPublicRoute(): void {
    const route = Adsp.configs.get('auth.defaultPublicRoute');
    this.logger.log(this, 'redirectToPublicRoute', route);
    this.router.navigate([route]);
  }

  getLoginPanels(tipoRuolo: string): Observable<LoginType[]> {
    return this.apiClient
      .request<{ metodi: LoginType[] }>(
        'getLoginPanels',
        null,
        { tipoRuolo },
        null
      )
      .pipe(map((res) => res.metodi));
  }

  register(): void {
    window.open(Adsp.configs.get('registrationEndpoint'), '_blank');
  }

  async refreshToken() {
    this.logger.log(this, 'refreshToken');
    const { oauthLoginType, loginType } = this._getLoginTypeByStore();
    await this.initOauth2(oauthLoginType, loginType);

    return this.oauthService.refreshToken();
  }

  async login(
    type: OauthLoginType = OauthLoginType.SUA,
    loginType: LoginType
  ): Promise<void> {
    this.logger.log(this, 'login', `type: ${type}`);
    this.storeLoginType(type, loginType);
    await this.initOauth2(type, loginType);

    if (Adsp.configs.get('auth.discovery')) {
      await this.loadDiscoveryDocument();
    }

    this.oauthService.initCodeFlow('', {
      fidp: Adsp.configs.get(`auth.${type}.fidp`),
    });
  }

  async initOauth2(
    type: OauthLoginType = OauthLoginType.SUA,
    loginType: LoginType
  ): Promise<void> {
    this.logger.log(this, 'initOauth2', `type: ${type}`);
    const authConfig: AuthConfig & { clientIdNoOtp: string } =
      Adsp.configs.get('auth.oauth2');
    if (loginType === LoginType.SUA_BASIC_AUTHENTICATION) {
      authConfig.clientId = authConfig.clientIdNoOtp;
    }
    this.oauthService.configure(authConfig);

    if (Adsp.configs.get('auth.discovery')) {
      await this.loadDiscoveryDocument();
    }
  }

  async tryLogin(): Promise<void> {
    this.logger.log(this, 'tryLogin');
    if (Adsp.configs.get('auth.enabled')) {
      const { oauthLoginType, loginType } = this._getLoginTypeByStore();
      await this.initOauth2(oauthLoginType, loginType);
      this._tryLogin();
    } else {
      this._loginSuccess();
    }
  }

  async setupAutomaticSilentRefresh(): Promise<void> {
    this.logger.log(this, 'setupAutomaticSilentRefresh');
    const { oauthLoginType, loginType } = this._getLoginTypeByStore();
    await this.initOauth2(oauthLoginType, loginType);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  navigateToLoginRoute() {
    const route = Adsp.configs.get('auth.defaultLoginRoute');
    this.logger.log(this, 'navigateToLoginRoute', route);
    this.router.navigate([route]);
  }

  private _initEventsOauthListeners() {
    this.oauthService.events.subscribe((events) => {
      if (events instanceof OAuthErrorEvent) {
        this._loginError();
      }
    });
  }

  private _initLogoutListener() {
    Adsp.events.auth.logout$.subscribe(async () => {
      this.logger.log(this, 'Adsp.events.auth.logout$.subscribe');
      this.logout();
    });
  }

  /*  private _initUnauthorizedListener() {
    merge(
      Adsp.events.auth.tokenNotValid$,
      Adsp.events.auth.apiUnauthorized$
    ).subscribe(async () => {
      this.logger.log(
        this,
        'merge(Adsp.events.auth.tokenNotValid$, Adsp.events.auth.apiUnauthorized$).subscribe'
      );

      const { oauthLoginType, loginType } = this._getLoginTypeByStore();
      await this.initOauth2(oauthLoginType, loginType);
      this.oauthService.logOut(true);
      Adsp.events.auth.emitRoleChange(true);
    });
  } */

  async logOutNoRevoke() {
    const { oauthLoginType, loginType } = this._getLoginTypeByStore();
    await this.initOauth2(oauthLoginType, loginType);
    this.oauthService.logOut(true);
    Adsp.events.auth.emitRoleChange(true);
  }

  async logout(): Promise<void> {
    this.logger.log(this, 'logout');
    if (!this.oauthService.loginUrl) {
      const { oauthLoginType, loginType } = this._getLoginTypeByStore();
      await this.initOauth2(oauthLoginType, loginType);
    }
    this.oauthService.revokeTokenAndLogout();
    this.cleanLoginInfosStoredValues();
  }

  private _initUrlToStoreListener() {
    Adsp.events.auth.urlToStore$.subscribe((url: string) => {
      this.logger.log(this, 'Adsp.events.auth.urlToStore$.subscribe');
      this.storeRedirectAppUri(url);
    });
  }

  private _getLoginTypeByStore(): {
    oauthLoginType: OauthLoginType;
    loginType: LoginType;
  } {
    this.logger.log(this, '_getLoginTypeByStore');
    return {
      oauthLoginType: this.storageService.getItem(
        keyStorageOauthLoginType
      ) as OauthLoginType,
      loginType: this.storageService.getItem(keyStorageLoginType) as LoginType,
    };
  }

  private async _tryLogin(): Promise<void> {
    await this.oauthService
      .tryLogin({
        onTokenReceived: () => {
          this._loginSuccess();
        },
      })
      .then(() => {
        this._loginSuccess();
      });
  }

  // private _checkRedirectAppUri() {
  //   this.userService
  //     .isAdmin()
  //     .pipe(
  //       catchError((e) => {
  //         this._checkRedirect();
  //         return throwError(e);
  //       })
  //     )
  //     .subscribe((isAdmin: boolean) => {
  //       this._checkRedirect(isAdmin);
  //     });
  // }

  private _checkRedirect(isAdmin: boolean = false) {
    this.logger.log(this, '_checkRedirect', `isAdmin: ${isAdmin}`);
    const url: string = this.storageService.getItem(keyStoragePostLoginUrl);

    if (isAdmin) {
      this.redirectTodefaultAdminPostLoginRoute();
    }

    if (url) {
      this.storageService.removeItem(keyStoragePostLoginUrl);

      if (!isAdmin) {
        this.router.navigate([url]);
      }
    } else {
      if (!isAdmin) {
        this.redirectToDefaultPostLoginRoute();
      }
    }
  }

  private _loginSuccess() {
    this.logger.log(this, 'Adsp.events.auth.emitLoginSuccess');

    // this._checkRedirectAppUri();
    // workaround for loop infinito di redirect
    setTimeout(() => {
      this._checkRedirect();
      Adsp.events.auth.emitLoginSuccess();
    }, 100);
  }

  private _loginError() {
    this.logger.log(this, 'Adsp.events.auth.emitLoginError');
    Adsp.events.auth.emitLoginError();
  }

  cleanLoginInfosStoredValues() {
    localStorage.removeItem(keyStorageOauthLoginType);
    localStorage.removeItem(keyStorageLoginType);
    localStorage.removeItem('accountType');
    localStorage.removeItem('codiceVerticale');
  }
}
