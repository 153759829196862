import { Component, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@eng-ds/translate';
import { BehaviorSubject, merge } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from './core/components';
import { AuthService } from './core/services/auth.service';
import { TranslateService } from './core/services/translate.service';
import { CookieAccepted, UserService } from './core/services/user.service';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-auth-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AutoUnsubscribe {
  showSessionExpiredModal = false;

  showForbiddenApiModal = false;

  showRoleModal = new BehaviorSubject<boolean>(false);
  // work around per eliminare il modale dal dom in maniera corretta
  loadRoleModal = false;

  showInitialWizardModal = false;
  // work around per eliminare il modale dal dom in maniera corretta
  loadInitialWizardModal = false;

  showSetCookieModal = false;
  storageKeyInitialWizardModal = 'initialWizardModalViewed';

  mustLogout = false;

  // variabile per le api error 401 da mandare in logout
  apiErrorLogout = false;
  private acceptedCookies: CookieAccepted;
  private userUuid: string;

  constructor(
    private _ngZone: NgZone,
    private i18nService: I18nService,
    translateService: TranslateService,
    // private logger: Logger,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    super();
    this._titlePage();
    this._initToBeCompleteProfileListener();
    this._initToCompletedProfileListener();
    this._initUnauthorizedListener();
    this._initForbiddenListener();
    this._initOpenRoleModalListener();
    this._initShowInitialWizardModalListener();
    this.userService.init();
    this.authService.setupAutomaticSilentRefresh();
    this.userService.mustSetCookies$.subscribe((val) => {
      this.showSetCookieModal = val;
    });
    Adsp.events.auth.user$.subscribe((user) => {
      if (!user && !localStorage.getItem('tipoCookie')) {
        this.showSetCookieModal = true;
      }
    });
  }

  // evento initialWizard
  // il modale con il wizard verrà mostrato solo al primo accesso

  closeRoleModal() {
    this.showRoleModal.next(false);

    // work around per eliminare il modale dal dom in maniera corretta
    setTimeout(() => {
      this.loadRoleModal = false;
    });
  }

  goToLoginPage() {
    // this.logger.log(this, 'goToLoginPage');
    this.authService.navigateToLoginRoute();
    this._closeSessionExpiredModal();
  }

  onRedirectToHome(): void {
    this.authService.redirectToPublicRoute();
    this.showForbiddenApiModal = false;
  }

  goToHomepage() {
    if (this.apiErrorLogout || this.mustLogout) {
      this.authService.logout();
      Adsp.events.auth.emitRoleChange(true);
    } else {
      // this.logger.log(this, 'goToHomepage');
      this.authService.removeStoredRedirectAppUri();
      this.authService.redirectToPublicRoute();
    }
    this._closeSessionExpiredModal();
  }

  saveCookies() {
    if (this.userUuid) {
      this.userService.setCookiesPreferences(this.acceptedCookies, new Date());
    } else {
      this.userService.saveCookiesPreferencesToLocalStorage(
        this.acceptedCookies
      );
      this._changeCookieSuccess();
    }
  }

  onCookieChange(val: CookieAccepted) {
    this.acceptedCookies = val;
  }

  private _changeCookieSuccess() {
    AgidWebComponents.notifications.success(
      this.i18nService.translate('SAVE_SUCCESS_TITLE'),
      this.i18nService.translate('COOKIES_SAVE_SUCCESS_TEXT')
    );
    this.showSetCookieModal = false;
  }

  // mantenendo su localStorage l'informazione
  private _initShowInitialWizardModalListener() {
    Adsp.events.portal.initialWizard$
      .pipe(takeUntil(this.destroy$))
      .subscribe((show: boolean) => {
        // this.logger.log(
        //   this,
        //   'Adsp.events.portal.initialWizard$.subscribe',
        //   `show: ${show}`
        // );
        if (show) {
          // se non è già stato visualizzato
          if (!localStorage.getItem(this.storageKeyInitialWizardModal)) {
            this._openInitialWizardModal();
          } else {
            // altrimenti redirect alla home
            this._redirectToHomepage();
          }
        } else {
          // salva su local storage che il wizard è stato visualizzato
          localStorage.setItem(this.storageKeyInitialWizardModal, 'viewed');
          this._closeInitialWizardModal();
          // altrimenti redirect alla home
          this._redirectToHomepage();
        }
      });
  }

  // evento openRoleModal
  private _initOpenRoleModalListener() {
    Adsp.events.header.openRoleModal$
      .pipe(takeUntil(this.destroy$))
      .subscribe((show: boolean) => {
        // this.logger.log(
        //   this,
        //   'Adsp.events.header.openRoleModal$.subscribe',
        //   `show: ${show}`
        // );
        if (show) {
          this._ngZone.run(() => this._openRoleModal());
          // this._openRoleModal();
        } else {
          this.closeRoleModal();
        }
      });
  }

  // evento profileToBeCompleted
  private _initToBeCompleteProfileListener() {
    Adsp.events.auth.profileToBeCompleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe((utenteEsistente) => {
        // this.logger.log(
        //   this,
        //   'Adsp.events.auth.profileToBeCompleted$.subscribe'
        // );
        this._goToProfile(utenteEsistente, 'TO_BE_COMPLETED');
      });
  }

  // evento profileCompleted
  private _initToCompletedProfileListener() {
    Adsp.events.auth.profileCompleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // this.logger.log(this, 'Adsp.events.auth.profileCompleted$.subscribe');
        this.router.navigate(['/']);
      });
  }
  errorMessage: string = null;

  // eventi apiUnauthorized e tokenNotValid
  private _initUnauthorizedListener() {
    merge(Adsp.events.auth.tokenNotValid$, Adsp.events.auth.apiUnauthorized$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((erroreCompleto: any) => {
        this.apiErrorLogout = false;
        const error = erroreCompleto.error;
        let messaggio = 'UNAUTHORIZED_MODAL_TEXT';
        if (error?.message) {
          messaggio = error?.message;
        } else if (error?.keyErrorMessage) {
          messaggio = error?.keyErrorMessage;
        }
        this.errorMessage =
          this.i18nService.translate(`ERROR_${messaggio}`) ===
          `ERROR_${messaggio}`
            ? this.i18nService.translate(messaggio)
            : this.i18nService.translate(`ERROR_${messaggio}`);
        // this.logger.log(
        //   this,
        //   'merge(Adsp.events.auth.tokenNotValid$, Adsp.events.auth.apiUnauthorized$).subscribe'
        // );
        this._openSessionExpiredModal();

        if (
          erroreCompleto.url.includes('/checkPostLogin') ||
          erroreCompleto.url.includes('/saveUserInfo') ||
          erroreCompleto.url.includes('/token')
        ) {
          this.apiErrorLogout = true;
        } else {
          this.authService.logOutNoRevoke();
        }
        this.authService.cleanLoginInfosStoredValues();
      });
  }

  // event apiForbidden
  private _initForbiddenListener() {
    Adsp.events.auth.apiForbidden$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        let messaggio = 'MODAL_FORBIDDEN_TEXT';
        if (error?.message) {
          messaggio = error?.message;
        } else if (error?.keyErrorMessage) {
          messaggio = error?.keyErrorMessage;
        }

        this.errorMessage =
          this.i18nService.translate(`ERROR_${messaggio}`) ===
          `ERROR_${messaggio}`
            ? this.i18nService.translate(messaggio)
            : this.i18nService.translate(`ERROR_${messaggio}`);
        // this.logger.log(this, 'Adsp.events.auth.apiForbidden$');
        this.authService.logout();
        this._openForbiddenApiModal();
      });
  }

  private _goToProfile(utenteEsistente: boolean, stato: string) {
    if (this.router.url.search('/profilo') > -1) {
      return;
    } else {
      this.router.navigate([
        '/profilo',
        { registration: !utenteEsistente, stato: stato },
      ]);
    }
  }

  private _closeSessionExpiredModal() {
    this.showSessionExpiredModal = false;
  }

  private _openSessionExpiredModal() {
    this.showSessionExpiredModal = true;
  }

  private _openForbiddenApiModal() {
    this.showForbiddenApiModal = true;
  }

  private _openRoleModal() {
    this.loadRoleModal = true;
    this.showRoleModal.next(true);
  }

  private _openInitialWizardModal() {
    this.loadInitialWizardModal = true;
    this.showInitialWizardModal = true;
  }

  private _closeInitialWizardModal() {
    this.showInitialWizardModal = false;

    // work around per eliminare il modale dal dom in maniera corretta
    setTimeout(() => {
      this.loadInitialWizardModal = false;
    });
  }

  private _redirectToHomepage() {
    // TODO workaround per collaudo
    document.location.href = document.location.origin;
  }

  private _titlePage(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(
            this.i18nService.translate(data) + ' - Autorità di Sistema Portuale'
          );
        }
      });
  }
}
