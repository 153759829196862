import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { EmptyRouteComponent } from './components/empty-route/empty-route.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AuthGuard } from './core/services/auth.guard';
import { UserResolver } from './core/services/user.resolver';
import { PrivacyPolicyPageComponent } from './components/privacy-policy-page/privacy-policy-page.component';
import { LegalNotesPageComponent } from './components/legal-notes-page/legal-notes-page.component';
import { AccessibilityPageComponent } from './components/accessibility-page/accessibility-page.component';

const routes: Routes = [
  {
    path: 'profilo',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
    /*  resolve: { user: UserResolver }, */
    data: { title: 'TITLE_PAGE_PROFILO' },
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'auth', component: AuthComponent },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPageComponent,
    data: { title: 'TITLE_PAGE_PRIVACY_POLICY' },
    /* resolve: { user: UserResolver }, */
  },
  {
    path: 'legal-notes',
    component: LegalNotesPageComponent,
    data: { title: 'TITLE_PAGE_LEGAL_NOTES' },
  },
  {
    path: 'accessibility',
    component: AccessibilityPageComponent,
    data: { title: 'TITLE_PAGE_ACCESSIBILITY' },
  },
  {
    path: 'accedi',
    component: LoginComponent,
    data: { title: 'TITLE_PAGE_ACCEDI' },
  },
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
