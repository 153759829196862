import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiGeneralPath = '/apigeneral/1.0';
const apiMguPath = '/apimgu/1.0';

const backendApi: ApiConfig[] = [
  {
    name: 'getUserInfo',
    method: 'POST',
    url: apiMguPath + '/auth/userInfo',
  },
  {
    name: 'checkPostLogin',
    method: 'POST',
    url: apiMguPath + '/auth/checkPostLogin',
  },
  {
    name: 'confirmProfile',
    method: 'POST',
    url: apiMguPath + '/auth/saveUserInfo',
  },
  {
    name: 'confirmOtp',
    method: 'POST',
    url: apiMguPath + '/auth/confirmOtp',
  },
  {
    name: 'sendOtp',
    method: 'PATCH',
    url: apiMguPath + '/utenti/address',
  },
  {
    name: 'changeProfile',
    method: 'PATCH',
    url: apiMguPath + '/utenti/changeProfile',
  },
  {
    name: 'changeNotifiche',
    method: 'PATCH',
    url: apiMguPath + '/contatti/notificaControlloAccesso/{uuid}',
  },
  {
    name: 'confirmOtpProfile',
    method: 'POST',
    url: apiMguPath + '/utenti/address/confirmOtp',
  },
  {
    name: 'getRoles',
    method: 'GET',
    url: apiMguPath + '/utenti/roles',
  },
  {
    name: 'getCookiesPreferences',
    method: 'GET',
    url: apiMguPath + '/cookie/{userUuid}',
  },
  {
    name: 'setCookiesPreferences',
    method: 'PATCH',
    url: apiMguPath + '/cookie/{tipo}',
  },
  {
    name: 'saveRole',
    method: 'PATCH',
    url: apiMguPath + '/utenti/roles',
  },
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getNazioni',
    method: 'GET',
    url: apiGeneralPath + '/config/nazioni',
  },
  {
    name: 'getProvince',
    method: 'GET',
    url: apiGeneralPath + '/config/province',
  },
  {
    name: 'getComuni',
    method: 'GET',
    url: apiGeneralPath + '/config/comuni/{uuid}',
  },
  {
    name: 'getLoginPanels',
    method: 'GET',
    url: apiMguPath + '/login/metodi',
  },
  {
    name: 'getDetailUser',
    method: 'GET',
    url: apiMguPath + '/utenti/detail',
  },
];

export const backendConfig: Omit<BackendConfig, 'getApiConfig' | 'prepareUrl'> =
  {
    // baseUrl: 'https://apim.sua.devel/apimgu/1.0',
    baseUrl: Adsp.configs.get('baseApiUrl'),
    api: backendApi,
  };
