import { Component, OnDestroy, OnInit } from '@angular/core';
import { I18nService } from '@eng-ds/translate';
import { ActionsFooterService } from '../../theme/actions-footer/actions-footer.service';

declare const Adsp: any;

@Component({
  selector: 'adsp-auth-accessibility-page',
  templateUrl: './accessibility-page.component.html',
  styleUrls: ['./accessibility-page.component.css'],
})
export class AccessibilityPageComponent implements OnInit, OnDestroy {
  constructor(
    private actionFooterService: ActionsFooterService,
    private i18n: I18nService
  ) {}
  accessibility_long_text: string = this.i18n.translate(
    'ACCESSIBILITY_LONG_TEXT'
  );
  texts: string[] = [];

  ngOnDestroy(): void {
    this.actionFooterService.destroy();
  }

  ngOnInit(): void {
    this._initActionsFooter();
    this.splitLongText();
  }

  private _initActionsFooter() {
    this.actionFooterService.add([
      {
        label: 'PROFILE_ACTIONS_FOOTER_GO_BACK',
        click: () => Adsp.router.goToHome(),
        position: 'center',
      },
    ]);
  }

  splitLongText() {
    this.texts = this.accessibility_long_text.split('<br>');
  }

  adjustFont(text: string): string {
    const text2 = text.replace('<bold>', '');
    return text2;
  }
}
