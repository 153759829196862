<app-form [form]="form"></app-form>
<adsp-auth-edit-mail-profilo
  *ngIf="
    isSignup
      ? user?.email === null
      : (!user.email && this.stato === 'TO_BE_COMPLETED') ||
        (user.email &&
          this.stato !== 'TO_BE_COMPLETED' &&
          user?.tipoRuolo === 'FO')
  "
  [mail]="user?.email"
  [uuid]="user?.uuid"
  [mainForm]="form"
  type="email"
></adsp-auth-edit-mail-profilo>
<adsp-auth-edit-mail-profilo
  *ngIf="
    !isSignup && user?.tipoRuolo === 'FO' && this.stato !== 'TO_BE_COMPLETED'
  "
  [mail]="user?.pec"
  [uuid]="user?.uuid"
  [mainForm]="form"
  type="pec"
></adsp-auth-edit-mail-profilo>
