import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

declare const Adsp: any;

@Component({
  template: '',
})
export class LogoutComponent {
  constructor(private authService: AuthService) {
    this.authService.redirectToPublicRoute();
  }
}
