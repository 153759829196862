import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiClientModule } from '@eng-ds/api-client';
import { TranslateModule } from '@eng-ds/translate';
import { IT } from 'src/assets/i18n/IT';
import { backendConfig } from '../config/backend.config';
import { AppHttpInterceptor } from './services/app-http.interceptor';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { TranslateService } from './services/translate.service';
import { UserResolver } from './services/user.resolver';
import { UserService } from './services/user.service';
import { UtilityService } from './services/utility.service';
import { LoggerModule } from '@eng-ds/logger';

declare const Adsp: any;

@NgModule({
  imports: [
    ApiClientModule.forRoot({
      backend: backendConfig,
    }),
    TranslateModule.forRoot({
      langs: [
        {
          code: 'it',
          isDefault: true,
          label: 'Italiano',
          translations: IT,
        },
      ],
    }),
    LoggerModule.forRoot(Adsp.configs.get('logger')),
  ],

  providers: [
    AuthService,
    UserService,
    AuthGuard,
    UserResolver,
    TranslateService,
    UtilityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
