import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logger } from '@eng-ds/logger';
import { I18nService } from '@eng-ds/translate';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { AutocompleteInput, Form, ValidationStatus } from 'src/app/shared/form';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-modal-role',
  templateUrl: './modal-role.component.html',
  styleUrls: ['./modal-role.component.scss'],
})
export class ModalRoleComponent extends BaseComponent implements OnInit {
  @Input() showRoleModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() closed = new EventEmitter();

  form: Form;
  user: User;

  saveErrorMessage: string;

  constructor(
    private logger: Logger,
    private userService: UserService,
    protected i18n: I18nService
  ) {
    super(i18n);
  }

  ngOnInit(): void {
    this.logger.log(this, 'ngOnInit');
    this._getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        this._initForm();
      });
  }

  save() {
    this.logger.log(this, 'save');
    Adsp.loading.show();
    this.userService
      .saveRole(this.form.value.ruolo)
      .pipe(
        catchError((err) => {
          this.logger.log(this, 'save:catchError');
          Adsp.loading.hide();
          this.saveErrorMessage = this.i18n.translate(
            'SAVE_ROLE_SAVE_ERROR_TEXT'
          );

          this.form.setErrors({});
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.logger.log(this, 'save:subscribe', response);
        Adsp.loading.hide();
        AgidWebComponents.notifications.success(
          this.i18n.translate('SAVE_ROLE_SAVE_SUCCESS_TITLE'),
          this.i18n.translate('SAVE_ROLE_SAVE_SUCCESS_TEXT')
        );
        // nosconde l'alert di error (se presente)
        this.saveErrorMessage = null;
        // per chiudere il modale
        Adsp.events.header.emitOpenRoleModal(false);
      });
  }

  close() {
    this.showRoleModal.next(false);
    this.closed.emit();
  }

  private _getUser() {
    this.logger.log(this, '_getUser');
    return this.userService.getInfoUserAlso();
  }

  private _initForm() {
    this.logger.log(this, '_initForm');
    this.form = new Form({
      header: {
        show: false,
      },
      controls: {
        ruolo: new AutocompleteInput({
          size: '12|12|12|12|12',
          required: true,
          label: 'MODAL_ROLE_CHOSE_LABEL',
          placeholder: 'MODAL_ROLE_CHOSE_LABEL',
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          options: this.userService.getRoles().pipe(
            map((roles) =>
              roles
                .filter((r) => r.label !== this.user.codiceRuolo)
                .map((r) => ({
                  label: this.i18n.translate(r.label),
                  value: r.value,
                }))
            )
          ),
        }),
      },
    });
  }
}
