<router-outlet></router-outlet>
<app-actions-footer role="toolbar"></app-actions-footer>

<!-- Modale del wizard iniziale: da mostrare solo al primo accesso -->
<adsp-modal-initial-wizard
  *ngIf="loadInitialWizardModal"
  [showInitialWizardModal]="showInitialWizardModal"
></adsp-modal-initial-wizard>

<!-- Modale di completamento dati profilo -->
<!--<adsp-modal-profile
  *ngIf="loadProfileModal"
  [showProfileModal]="showProfileModal"
></adsp-modal-profile>-->

<!-- Modale di scelta ruolo -->
<adsp-modal-role
  *ngIf="loadRoleModal"
  [showRoleModal]="showRoleModal"
  (closed)="closeRoleModal()"
></adsp-modal-role>

<!-- Modale sessione scaduta Unauthorized -->
<agid-modal
  [open]="showSessionExpiredModal"
  position="center"
  keyboard="false"
  static-backdrop
>
  <agid-modal-header left-icon="it-warning-circle" left-icon-color="warning">{{
    "UNAUTHORIZED_MODAL_TITLE" | translate
  }}</agid-modal-header>

  <agid-modal-body>
    <p class="mt-2">{{ errorMessage | translate }}</p>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button size="small" outline (click)="goToHomepage()">
      {{ "UNAUTHORIZED_MODAL_HOMEPAGE_BTN" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>

<agid-modal
  [open]="showForbiddenApiModal"
  position="center"
  keyboard="false"
  static-backdrop
>
  <agid-modal-header left-icon="it-warning-circle" left-icon-color="warning">{{
    "MODAL_FORBIDDEN_TITLE" | translate
  }}</agid-modal-header>

  <agid-modal-body class="p-4">
    <p>{{ errorMessage | translate }}</p>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button size="small" outline (click)="onRedirectToHome()">
      {{ "MODAL_FORBIDDEN_BUTTONS_TO_HOME" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>

<agid-modal
  [open]="showSetCookieModal"
  position="center"
  keyboard="false"
  size="large"
  scrollable
  static-backdrop
>
  <agid-modal-header>{{
    "TITLE_PAGE_PRIVACY_POLICY" | translate
  }}</agid-modal-header>

  <agid-modal-body>
    <adsp-auth-privacy-policy
      (onChange)="onCookieChange($event)"
    ></adsp-auth-privacy-policy>
  </agid-modal-body>

  <agid-modal-footer>
    <agid-button size="small" (click)="saveCookies()">
      {{ "BTN_SAVE" | translate }}
    </agid-button>
  </agid-modal-footer>
</agid-modal>
