<!-- MODAL WIZARD -->
<agid-modal
  [open]="showInitialWizardModal"
  size="large"
  position="center"
  keyboard="false"
  static-backdrop
>
  <agid-modal-body class="modal-body-style">
    <div
      class="font-size-32 font-weight-bold title text-center color-dark-gray mt-4"
    >
      {{ "DESKTOP_DELEGATION_MODAL_WIZARD_TITLE" | translate }}
    </div>
    <hr class="color-hr font-weight-bold" />
    <agid-stepper [activeStep]="currentStep">
      <agid-stepper-body>
        <div class="content-modal" slot="step1">
          <span class="font-size-16 text-style-regular color-black">{{
            "DESKTOP_DELEGATION_MODAL_WIZARD_DESCRIPTION" | translate
          }}</span>
          <div class="vertycal-line-modal">
            <h5
              class="ml-5 mt-5 card-title font-size-18 big-heading font-weight-bold color-dark-gray"
            >
              <agid-icon
                class="cursor-pointer"
                icon="it-pin"
                size="large"
                color="primary"
              ></agid-icon>
              {{ "DESKTOP_DELEGATION_MODAL_CREATE_TITLE" | translate }}
            </h5>

            <div
              class="card-text font-size-16 mb-3 ml-5 text-style-regular color-black"
              [innerHTML]="'DESKTOP_DELEGATION_MODAL_CREATE_TEXT' | translate"
            ></div>
            <a
              class="read-more font-size-16 text-decoration-none ml-5"
              [routerLink]="['sua', 'deleghe']"
            >
              <span class="text primary text-decoration-none"
                >{{ "DESKTOP_DELEGATION_MODAL_OPEN_PROXIES" | translate }}
                <agid-icon
                  class="cursor-pointer color-icon-proxies"
                  icon="it-chevron-right"
                  size="small"
                  color="primary"
                ></agid-icon
              ></span>
            </a>
          </div>
        </div>

        <div class="content-modal" slot="step2">
          <span class="font-size-16 text-style-regular color-black">{{
            "DESKTOP_DELEGATION_MODAL_WIZARD_DESCRIPTION" | translate
          }}</span>

          <div class="vertycal-line-modal">
            <h5
              class="mt-5 ml-5 card-title font-size-18 big-heading font-weight-bold color-dark-gray"
            >
              <agid-icon
                class="cursor-pointer"
                icon="it-pin"
                size="large"
                color="primary"
              ></agid-icon>
              {{ "DESKTOP_DELEGATION_MODAL_ASSOCIATE_TITLE" | translate }}
            </h5>
            <p
              class="card-text font-size-16 mb-3 ml-5 text-style-regular color-black"
              [innerHTML]="
                'DESKTOP_DELEGATION_MODAL_ASSOCIATE_TEXT' | translate
              "
            ></p>
            <a
              class="read-more font-size-16 text-decoration-none ml-5"
              [routerLink]="['sua', 'deleghe']"
            >
              <span class="text primary"
                >{{ "DESKTOP_DELEGATION_MODAL_NEW_PROXIES" | translate }}
                <agid-icon
                  class="cursor-pointer"
                  icon="it-chevron-right"
                  size="small"
                  color="primary"
                ></agid-icon
              ></span>
            </a>
          </div>
        </div>
      </agid-stepper-body>
      <agid-stepper-nav>
        <div class="row m-auto mb-5 mt-5">
          <div class="col-12">
            <div
              class="buttons-stepper-bottom form-check-inline form-check size-button button-check-style"
            >
              <input
                name="gruppo2"
                type="radio"
                id="radio4"
                checked
                (click)="prev()"
              />
              <label for="radio4"></label>
            </div>
            <div
              class="buttons-stepper-bottom form-check-inline form-check size-button button-check-style"
            >
              <input name="gruppo2" type="radio" id="radio5" (click)="next()" />
              <label for="radio5"></label>
            </div>
          </div>
        </div>
      </agid-stepper-nav>
    </agid-stepper>

    <div class="text-center mt-5">
      <agid-button color="primary" size="small" (click)="ok()">
        {{ "DESKTOP_DELEGATION_MODAL_BUTTON_CONFIRM" | translate }}
      </agid-button>
    </div>
  </agid-modal-body>
  <agid-modal-footer> </agid-modal-footer>
</agid-modal>
