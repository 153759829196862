import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@eng-ds/logger';
import { I18nService } from '@eng-ds/translate';
import { throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/core/components';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { Form } from 'src/app/shared/form';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-modal-profile',
  templateUrl: './modal-profile.component.html',
})
export class ModalProfileComponent extends AutoUnsubscribe implements OnInit {
  @Input() showProfileModal: boolean;

  form: Form;
  user: User;
  error = false;
  logoutText = this.i18n.translate('PROFILE_ALERT_LOGOUT_TEXT');
  saveErrorMessage: string;

  // campi in sola lettura sul form
  readonlyFields = ['nome', 'cognome', 'email'];

  constructor(
    private authService: AuthService,
    private logger: Logger,
    private userService: UserService,
    private i18n: I18nService
  ) {
    super();
  }

  ngOnInit(): void {
    this.logger.log(this, 'ngOnInit');
    this._initForm();
    this._getUser();
  }

  private _getUser() {
    this.logger.log(this, '_getUser');
    this.userService
      .getInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.logger.log(this, '_getUser.subscribe', user);
        this.user = user;
      });
  }

  private _initForm() {
    this.logger.log(this, '_initForm');
    this.form = new Form({
      header: {
        show: false,
      },
      controls: {},
    });
  }

  logout() {
    this.authService.logout();
  }

  save() {
    this.logger.log(this, '_save');
    Adsp.loading.show();
    const data = this.form.getRawValue();
    delete data.noop;
    delete data.noop2;
    this.userService
      .confirmProfile({ ...data, uuid: this.user.uuid })
      .pipe(
        catchError((err) => {
          this.logger.log(this, '_save:catchError');
          Adsp.loading.hide();
          this.saveErrorMessage = this.i18n.translate(
            'PROFILE_SAVE_ERROR_TEXT'
          );
          this.form.setErrors({});
          document.querySelector('.modal-body').scrollTo(0, 0);
          this.error = true;
          return throwError(err);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.logger.log(this, '_save:subscribe', user);
        Adsp.loading.hide();

        // controlla se il salvataggio non è andato bene
        if (this.userService.userIsToBeCompleted(user)) {
          this.saveErrorMessage = this.i18n.translate(
            'PROFILE_SAVE_ERROR_TEXT'
          );

          this.form.setErrors({});
          document.querySelector('.modal-body').scrollTo(0, 0);
          return;
        }

        // controlla se il salvataggio è andato bene
        if (this.userService.userIsCompleted(user)) {
          // nosconde l'alert di error (se presente)
          this.saveErrorMessage = null;

          // per chiudere il modale
          Adsp.events.auth.emitProfileCompleted();

          // TODO workaround per collaudo
          // da modificare nel momento in cui viene
          // riabilitata la conferma della mail su wso2
          document.location.href = document.location.origin;

          // mostra il messaggio di success solo se non deve mostrare il modale di conferma otp
          AgidWebComponents.notifications.success(
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TITLE'),
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TEXT')
          );
          return;
        }

        // controlla se deve essere mostrato il modale di conferma otp
        if (this.userService.userIsOtpConfirm(user)) {
          // apre il modale di conferma otp
          this.userService.emitShowOtp();

          // per chiudere il modale
          Adsp.events.auth.emitProfileCompleted();
          return;
        }
      });
  }
}
