import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseInput, CheckboxInput, Form } from 'src/app/shared/form';
import { CookieAccepted, UserService } from '../../core/services/user.service';
import { I18nService } from '@eng-ds/translate';

@Component({
  selector: 'adsp-auth-privacy-policy',
  templateUrl: './privacy-policy.component.html',
})
export class PrivacyPolicyComponent implements OnInit {
  @Input() userUuid: string;
  @Output() onChange = new EventEmitter<CookieAccepted>();
  form: Form;
  private _localStorageCookieData: {
    dataAccettazione: Date;
    dataModifica: Date;
    tipo: CookieAccepted;
  };
  policy_long_text: string = this.i18n.translate('PRIVACY_POLICY_LONG_TEXT');
  texts: string[] = [];

  constructor(private userService: UserService, private i18n: I18nService) {
    this._localStorageCookieData =
      this.userService.getCookiesPreferencesFromLocalStorage();
    this.splitLongText();
  }

  get cookieValue(): CookieAccepted {
    return this.form?.get('technical').value &&
      this.form.get('thirdParties').value
      ? CookieAccepted.All
      : CookieAccepted.Tecnico;
  }

  ngOnInit(): void {
    const size = '12|12|12|12|12';
    if (this.userUuid) {
      this.userService
        .getCookiesPreferences(this.userUuid)
        .subscribe((cookieRes) => {
          // change to spread once dates are formatted
          this._localStorageCookieData.tipo = cookieRes.tipo;
          this._localStorageCookieData.dataModifica = new Date(
            cookieRes.dataModifica
          );
          this._localStorageCookieData.dataAccettazione = new Date(
            cookieRes.dataAccettazione
          );
          if (this.form.get('thirdParties')) {
            this.form
              .get('thirdParties')
              .setValue(this._localStorageCookieData.tipo);
          }
        });
    }
    this.form = new Form({
      header: {
        show: false,
      },
      controls: this.getControls(size),
    });
    this.onChange.emit(this.cookieValue);
  }

  private getControls(size: string) {
    return {
      technical: new CheckboxInput({
        label: 'AUTH_PRIVACY_POLICY_COOKIES_TECHNICAL_CHECKBOX',
        size,
        value: true,
        readonly: true,
        valueChange: () => this.onChange.emit(this.cookieValue),
      }) as BaseInput<any>,
      thirdParties: new CheckboxInput({
        label: 'AUTH_PRIVACY_POLICY_COOKIES_THIRD_PARTIES_CHECKBOX',
        size,
        value: this._localStorageCookieData.tipo === CookieAccepted.All,
        valueChange: () => this.onChange.emit(this.cookieValue),
      }) as BaseInput<any>,
    };
  }

  splitLongText() {
    this.texts = this.policy_long_text.split('<br>');
  }

  adjustFont(text: string): string {
    const text2 = text.replace('<bold>', '');
    return text2;
  }
}
