import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsFooterService } from '../../theme/actions-footer/actions-footer.service';
import { I18nService } from '@eng-ds/translate';
import { User } from '../../core/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieAccepted, UserService } from '../../core/services/user.service';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-auth-legal-notes-page',
  templateUrl: './legal-notes-page.component.html',
  styleUrls: ['./legal-notes-page.component.css'],
})
export class LegalNotesPageComponent implements OnInit, OnDestroy {
  constructor(
    private actionFooterService: ActionsFooterService,
    private i18n: I18nService
  ) {}
  legal_notes_long_text: string = this.i18n.translate('LEGAL_NOTES_LONG_TEXT');
  texts: string[] = [];

  ngOnDestroy(): void {
    this.actionFooterService.destroy();
  }

  ngOnInit(): void {
    this._initActionsFooter();
    this.splitLongText();
  }

  private _initActionsFooter() {
    this.actionFooterService.add([
      {
        label: 'PROFILE_ACTIONS_FOOTER_GO_BACK',
        click: () => Adsp.router.goToHome(),
        position: 'center',
      },
    ]);
  }

  splitLongText() {
    this.texts = this.legal_notes_long_text.split('<br>');
  }

  adjustFont(text: string): string {
    const text2 = text.replace('<bold>', '');
    return text2;
  }
}
