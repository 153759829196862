import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ProfileComponent } from '../../components/profile/profile.component';

declare const Adsp: any;

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<ProfileComponent> {
  constructor(private oauthService: OAuthService) {}

  canActivate() {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return true;
    } else {
      Adsp.events.auth.emitUrlToStore(window.location.pathname);
      Adsp.events.auth.emitTokenNotValid();
      return false;
    }
  }

  canDeactivate(
    component: ProfileComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!component.exit) {
      Adsp.events.header.emitShowAdspHeader(true);
    }
    return component.exit;
  }
}
