import {Component, Input, OnInit} from '@angular/core';
import {BootstrapSize, Form, TextInput, ValidationStatus,} from '../../../../shared/form';
import {I18nService} from '@eng-ds/translate';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'adsp-auth-edit-mail-profilo',
  templateUrl: './edit-mail-profilo.component.html',
  styleUrls: ['./edit-mail-profilo.component.css'],
})
export class EditMailProfiloComponent implements OnInit {
  @Input() mainForm: Form;
  @Input() mail: string;
  @Input() uuid: string;
  @Input() type: 'pec' | 'email';
  showOtpModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fieldConfig;
  form: Form;

  constructor(private i18n: I18nService) {}

  get size(): BootstrapSize {
    return this.form?.get(this.type)?.size as BootstrapSize;
  }

  ngOnInit(): void {
    this._initField();
    if (this.type === 'email') {
      this.mainForm.addControl(this.type, new TextInput({
          ...this.fieldConfig,
          ...{
            formGroupClass: ['d-none'],
            readonly: false,
            // necessario per non renderizzare un div vuoto
            size: '0'
          }
        })
      );
      this.mainForm.updateValueAndValidity();
    }
    this.form = new Form({
      header: {show: false},
      filter: false,
      controls: {
        [this.type]: new TextInput(this.fieldConfig),
      },
    });
  }

  openOtp() {
    this._initField();
    this.showOtpModal.next(true);
  }

  onOtpDone(address: string) {
    this.mail = address;
    this.fieldConfig.value = address;
    this.form?.get(this.type)?.setValue(address);
    this.form?.markAllAsTouched();
    if (this.type === 'email') {
      this.mainForm?.get(this.type)?.setValue(address);
      this.mainForm?.markAllAsTouched();
    }
  }

  private _initField() {
    const size = '12|12|12|4|4';
    this.fieldConfig =
      this.type === 'email'
        ? {
          type: 'text',
          size,
          readonly: true,
          value: this.mail,
          required: true,
          label: 'PROFILE_FORM_INPUT_EMAIL_LABEL',
          placeholder: 'PROFILE_FORM_INPUT_EMAIL_PLACEHOLDER',
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.WARNING(
              (control) => control.hasError('pattern'),
              {
                text: this.i18n.translate('VALIDATIONS_PATTERN_EMAIL'),
              }
            ),
          ],
          pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
        }
        : {
          type: 'text',
          size,
          readonly: true,
          value: this.mail,
          label: 'USER_PROFILE_FORM_INPUT_PEC_LABEL',
          placeholder: 'USER_PROFILE_FORM_INPUT_PEC_PLACEHOLDER',
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.WARNING(
              (control) => control.hasError('pattern'),
              {
                text: this.i18n.translate('VALIDATIONS_PATTERN_EMAIL'),
              }
            ),
          ],
          pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
        };

  }
}
