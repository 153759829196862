import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionsFooterService} from '../../theme/actions-footer/actions-footer.service';
import {I18nService} from '@eng-ds/translate';
import {User} from '../../core/models/user';
import {ActivatedRoute} from '@angular/router';
import {CookieAccepted, UserService} from '../../core/services/user.service';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-auth-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.css']
})
export class PrivacyPolicyPageComponent implements OnInit, OnDestroy {

  acceptedCookies: CookieAccepted;
  userUuid: string;

  constructor(private actionFooterService: ActionsFooterService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private i18n: I18nService) { }

  ngOnDestroy(): void {
    this.actionFooterService.destroy();
  }

  ngOnInit(): void {
    this._initActionsFooter();
    this.userUuid = (this.activatedRoute.snapshot.data?.user as User)?.uuid;
  }

  changedValue(val: CookieAccepted) {
    this.acceptedCookies = val;
  }

  private _initActionsFooter() {
    this.actionFooterService.add([
      {
        label: 'PROFILE_ACTIONS_FOOTER_SAVE',
        click: () => this._save(),
        position: 'center',
      },
    ]);
  }

  private _save() {
    Adsp.loading.show();
    if (this.userUuid) {
      this.userService
        .setCookiesPreferences(this.acceptedCookies, new Date())
        .subscribe(() => this._changeCookieSuccess());
    } else {
      this.userService.saveCookiesPreferencesToLocalStorage(this.acceptedCookies);
      this._changeCookieSuccess();
    }
  }

  private _changeCookieSuccess() {
    AgidWebComponents.notifications.success(
      this.i18n.translate('SAVE_SUCCESS_TITLE'),
      this.i18n.translate('COOKIES_SAVE_SUCCESS_TEXT')
    );
  }
}
