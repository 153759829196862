import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@eng-ds/logger';
import { I18nService } from '@eng-ds/translate';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/components';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { Form } from 'src/app/shared/form';
import { ActionsFooterService } from 'src/app/theme/actions-footer/actions-footer.service';
import { HttpErrorResponse } from '@angular/common/http';

declare const Adsp: any;
declare const AgidWebComponents: any;

@Component({
  selector: 'adsp-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  formsInvalid$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  form: Form;
  user: User;

  // campi in sola lettura sul form
  readonlyFields = [
    'sesso',
    'nome',
    'cognome',
    'codiceFiscale',
    'dataNascita',
    'nazioneNascitaUuid',
    'provinciaNascitaUuid',
    'comuneNascitaUuid',
    'cittaNascita',
  ];
  doneSignup = true;
  isSignup = false;
  showForbiddenItalianApiModal = false;
  stato = null;
  exit: boolean = false;
  constructor(
    private logger: Logger,
    private actionFooterService: ActionsFooterService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userService: UserService,
    protected i18n: I18nService
  ) {
    super(i18n);
    /* per non fargli cambiare ruolo o farlo andare in altre rotte se non Home */
    Adsp.events.header.emitShowAdspHeader(true);
    this.route.paramMap.subscribe((params) => {
      this.isSignup = params.get('registration') === 'true';
      if (params?.get('stato') === 'TO_BE_COMPLETED') {
        this.stato = 'TO_BE_COMPLETED';
      }

      this.doneSignup = !this.isSignup;

      if (!this.isSignup && !this.stato) {
        this.exit = true;
      }
    });
  }
  ngAfterViewInit(): void {
    this._initActionsFooter();
  }

  ngOnInit(): void {
    this.logger.log(this, 'ngOnInit');
    this.userService.getInfoUser(this.isSignup).subscribe((data) => {
      this.user = data;
    });

    this._initForm();
  }

  ngOnDestroy(): void {
    Adsp.events.header.emitShowAdspHeader(false);
    this.logger.log(this, 'ngOnDestroy');
    this.actionFooterService.destroy();
  }

  goToHome() {
    this.exit = true;
    this.logger.log(this, 'goToHome');
    Adsp.router.goToHome();
  }

  create(data) {
    this.userService
      .confirmProfile({ ...data, uuid: this.user.uuid })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          /*   if (
            err?.status === 401 &&
            err?.error?.keyErrorMessage === 'ERROR_ITALIAN_WSO2_UNAUTHORIZED'
          ) {
            this.showForbiddenItalianApiModal = true;
          } else */ {
            return !this.isSignup
              ? this._handleErrorMessage(err.error, true)
              : throwError(err);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.doneSignup = true;
        this.exit = true;
        // controlla se il salvataggio è andato bene
        if (this.userService.userIsCompleted(user)) {
          // per chiudere il modale
          Adsp.events.auth.emitProfileCompleted();

          // TODO workaround per collaudo
          // da modificare nel momento in cui viene
          // riabilitata la conferma della mail su wso2
          document.location.href = document.location.origin;

          // mostra il messaggio di success solo se non deve mostrare il modale di conferma otp
          AgidWebComponents.notifications.success(
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TITLE'),
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TEXT')
          );
          return;
        }
      });
  }

  _logout() {
    this.exit = true;
    this.authService.logout();
  }

  private _initForm() {
    this.logger.log(this, '_initForm');
    this.form = new Form({
      header: {
        show: false,
      },
      controls: {},
    });
  }

  _initFormStatusChange(): void {
    this.logger.log(this, '_initFormStatusChange');
    this.form.statusChanges
      .pipe(
        tap((status: string) => {
          if (status === 'VALID') {
            this.formsInvalid$.next(false);
          } else {
            this.formsInvalid$.next(true);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private _initActionsFooter(): void {
    this.logger.log(this, '_initActionsFooter');
    this.actionFooterService.add([
      {
        label: 'PROFILE_ACTIONS_FOOTER_SAVE',
        click: () => this._save(),
        disabled: this.formsInvalid$,
        position: 'center',
      },
      (!this.doneSignup || this.stato === 'TO_BE_COMPLETED') && {
        label: 'PROFILE_ACTIONS_FOOTER_LOGOUT',
        click: () => this._logout(),
        class: ['mr-3'],
        position: 'right',
        status: 'warning',
      },
      this.stato !== 'TO_BE_COMPLETED' &&
        this.doneSignup && {
          label: 'PROFILE_ACTIONS_FOOTER_LOGOUT',
          click: () => {
            this.goToHome();
          },
          class: ['ml-3'],
          position: 'left',
          outline: true,
          status: 'primary',
        },
    ]);
  }

  private _save() {
    this.logger.log(this, '_save');
    Adsp.loading.show();
    const data = this.form.getRawValue();
    delete data.noop;
    delete data.noop2;
    data.tipoRuolo = localStorage.getItem('accountType') || null;
    if (this.stato === 'TO_BE_COMPLETED' || this.isSignup) {
      this.create(data);
    } else {
      this.change(data);
    }
  }

  private change(data) {
    let obs = null;
    if (this.user?.tipoRuolo !== 'FO') {
      obs = this.userService.changeNotifiche(
        data.notificaControlloAccessi,
        this.user.uuid
      );
    } else {
      obs = this.userService.changeProfile({ ...data, uuid: this.user.uuid });
    }
    obs
      .pipe(
        catchError((err) => {
          return this._handleErrorMessage(err.error, true);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.exit = true;
        this.logger.log(this, '_save:subscribe', user);

        // controlla se deve essere mostrato il modale di conferma otp
        if (this.userService.userIsOtpConfirm(user)) {
          // apre il modale di conferma otp
          this.userService.emitShowOtp();
        } else {
          // profilo aggiornato senza bisogno di conferma otp
          AgidWebComponents.notifications.success(
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TITLE'),
            this.i18n.translate('PROFILE_SAVE_SUCCESS_TEXT')
          );
        }
      });
  }

  onRedirectToHome() {
    this.exit = true;
    this.doneSignup = true;
    this.authService.redirectToPublicRoute();
    this.showForbiddenItalianApiModal = false;
  }
}
