import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { Logger } from '@eng-ds/logger';
import { I18nService } from '@eng-ds/translate';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AutoUnsubscribe } from 'src/app/core/components';
import { User } from 'src/app/core/models/user';
import { UtilityService } from 'src/app/core/services/utility.service';
import {
  AutocompleteInput,
  BaseInput,
  CheckboxInput,
  DateInput,
  Form,
  NopInput,
  TextInput,
  ValidationStatus,
} from 'src/app/shared/form';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'adsp-form-profile',
  templateUrl: './form-profile.component.html',
})
export class FormProfileComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: Form;
  @Input() user: User;
  size = '12|12|12|4|4';
  uuidItalia = '85249f2a-e86c-11ea-adc1-0242ac120002';

  @Output() statusForm: EventEmitter<void> = new EventEmitter<void>();
  // campi in sola lettura sul form
  @Input() readonlyFields: string[] = [];
  @Input() showDetail = false;
  isSignup = false;
  private fiscalCodeValidationPattern =
    '(^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$)|(^[0-9]{11}$)';
  private fieldsResidenza;
  private fieldsNascita;
  private fieldsEstera;
  private fieldsMail;
  nazioni = null;
  stato: string = null;
  registrazioneCIECNS = false;
  constructor(
    private logger: Logger,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private i18n: I18nService
  ) {
    super();
    this.activatedRoute.paramMap.subscribe((params) => {
      this.isSignup = params.get('registration') === 'true';
      if (params?.get('stato') === 'TO_BE_COMPLETED') {
        this.stato = 'TO_BE_COMPLETED';
      }
    });
  }

  ngOnInit(): void {
    if (this.isSignup && !this.user.email) this.registrazioneCIECNS = true;
    this.logger.log(this, 'ngOnInit');
    this.fieldsResidenza = {
      provinciaResidenzaUuid: new AutocompleteInput({
        size: this.size,
        value: this.user?.provinciaResidenzaUuid,
        label: 'PROFILE_FORM_INPUT_RESIDENTIAL_PROVINCE',
        placeholder: 'PROFILE_FORM_INPUT_RESIDENTIAL_PROVINCE',
        options: of([]),
        valueChange: (value: string) => {
          this.logger.log(
            this,
            'provinciaResidenzaUuid:valueChange',
            `value: ${value}`
          );
          if (value) {
            this.form.get('comuneResidenzaUuid').reset();
            this._setCitiesOptions('comuneResidenzaUuid', value);
          }
        },
      }),
      comuneResidenzaUuid: new AutocompleteInput({
        size: this.size,
        value: this.user?.comuneResidenzaUuid,
        label: 'PROFILE_FORM_INPUT_RESIDENTIAL_CITY',
        placeholder: 'PROFILE_FORM_INPUT_RESIDENTIAL_CITY',
        options: of([]),
      }),
    };
    this.fieldsNascita = {
      provinciaNascitaUuid: new AutocompleteInput({
        size: this.size,
        value: this.user?.provinciaNascitaUuid,
        required: true,
        readonly: this.user?.provinciaNascitaUuid ? true : false,
        label: 'PROFILE_FORM_INPUT_BIRTH_PROVINCE',
        placeholder: 'PROFILE_FORM_INPUT_BIRTH_PROVINCE',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: of([]),
        valueChange: (value: string) => {
          this.logger.log(
            this,
            'provinciaNascitaUuid:valueChange',
            `value: ${value}`
          );
          if (value) {
            this.form.get('comuneNascitaUuid').reset();
            this._setCitiesOptions('comuneNascitaUuid', value);
          }
        },
      }),
      comuneNascitaUuid: new AutocompleteInput({
        size: this.size,
        value: this.user?.comuneNascitaUuid,
        required: true,
        readonly: this.user?.comuneNascitaUuid ? true : false,
        label: 'PROFILE_FORM_INPUT_BIRTH_CITY',
        placeholder: 'PROFILE_FORM_INPUT_BIRTH_CITY',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: of([]),
      }),
    };
    this.fieldsEstera = {
      cittaEsteraNascita: new TextInput({
        type: 'text',
        size: this.size,
        value: this.user?.cittaEsteraNascita,
        required: true,
        readonly: this.user?.cittaEsteraNascita ? true : false,
        label: 'PROFILE_FORM_INPUT_CITY_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_CITY_PLACEHOLDER',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
      }),
      cittaEsteraResidenza: new TextInput({
        type: 'text',
        size: this.size,
        value: this.user?.cittaEsteraResidenza,
        label: 'PROFILE_FORM_INPUT_CITY_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_CITY_PLACEHOLDER',
      }),
    };
    this.utilityService.getNazioni().subscribe((data) => {
      this.nazioni = data;
      this._initForm();
    });
  }

  private _initForm() {
    this.logger.log(this, '_initForm');
    const fields = this._getFormFields();

    // tslint:disable-next-line: forin
    for (const name in fields) {
      this.form.addControl(name, fields[name]);
    }
    this.form
      ?.get('nazioneNascitaUuid')
      ?.setValue(this.user?.nazioneNascitaUuid);
    this.form
      ?.get('provinciaNascitaUuid')
      ?.setValue(this.user?.provinciaNascitaUuid);
    this.form?.get('comuneNascitaUuid')?.setValue(this.user?.comuneNascitaUuid);
    this.form
      ?.get('cittaEsteraNascita')
      ?.setValue(this.user?.cittaEsteraNascita);
    if (this.user?.tipoRuolo === 'FO') {
      this.form
        ?.get('nazioneResidenzaUuid')
        ?.setValue(this.user?.nazioneResidenzaUuid);
      this.form
        ?.get('provinciaResidenzaUuid')
        ?.setValue(this.user?.provinciaResidenzaUuid);
      this.form
        ?.get('comuneResidenzaUuid')
        ?.setValue(this.user?.comuneResidenzaUuid);
      this.form
        ?.get('cittaEsteraResidenza')
        ?.setValue(this.user?.cittaEsteraResidenza);
    }
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    this.statusForm.emit();
  }

  private _setProvincesAndCitiesOptions(
    provinceFieldName: string,
    cityFieldName: string,
    nationUuid: string
  ) {
    this.logger.log(
      this,
      '_setProvincesAndCitiesOptions',
      `provinceFieldName: ${provinceFieldName}`,
      `cityFieldName: ${cityFieldName}`,
      `nationUuid: ${nationUuid}`
    );
    const provinceField = this.form?.get(
      provinceFieldName
    ) as AutocompleteInput;
    provinceField?.setOptions(
      this.utilityService.getProvince(nationUuid).pipe(
        tap(() => {
          if (provinceField.value) {
            this._setCitiesOptions(cityFieldName, provinceField.value);
          }
        })
      )
    );
  }

  private _setCitiesOptions(cityFieldName: string, provinceUuid: string) {
    this.logger.log(
      this,
      '_setCitiesOptions',
      `cityFieldName: ${cityFieldName}`,
      `provinceUuid: ${provinceUuid}`
    );
    (this.form?.get(cityFieldName) as AutocompleteInput).setOptions(
      this.utilityService.getComuni(provinceUuid)
    );
  }

  private _checkFiscalCodeMandatory(nationUuid: string) {
    if (!nationUuid || nationUuid === this.uuidItalia) {
      this.form
        .get('codiceFiscale')
        .setValidators([
          Validators.required,
          Validators.pattern(this.fiscalCodeValidationPattern),
        ]);
      this.form.get('codiceFiscale').updateValueAndValidity();
      this.form.get('codiceFiscale').markAllAsTouched();
    } else {
      this.form.get('codiceFiscale').clearValidators();
      // this.form.get('codiceFiscale').reset();
    }
  }

  private _getFormFields(): { [key: string]: BaseInput<any> } {
    this.logger.log(this, '_getFormFields');
    const size = this.size;
    this.fieldsMail = {
      email: new TextInput({
        type: 'text',
        size: '12|12|12|4|4',
        value: this.user?.email,
        required: true,
        readonly: true,
        label: 'PROFILE_FORM_INPUT_EMAIL_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_EMAIL_PLACEHOLDER',
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_EMAIL'),
          }),
        ],
        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
      }),
      pec: new TextInput({
        type: 'text',
        size: '12|12|12|4|4',
        value: this.user?.pec,
        label: 'USER_PROFILE_FORM_INPUT_PEC_LABEL',
        placeholder: 'USER_PROFILE_FORM_INPUT_PEC_PLACEHOLDER',
        readonly: true,
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_EMAIL'),
          }),
        ],
        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
      }),
    };
    return {
      nome: new TextInput({
        type: 'text',
        size,
        required: true,
        readonly: this.readonlyFields.includes('nome'),
        value: this.user?.nome,
        label: 'PROFILE_FORM_INPUT_NAME_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_NAME_PLACEHOLDER',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
      }),
      cognome: new TextInput({
        type: 'text',
        size,
        value: this.user?.cognome,
        required: true,
        readonly: this.readonlyFields.includes('cognome'),
        label: 'PROFILE_FORM_INPUT_LASTNAME_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_LASTNAME_PLACEHOLDER',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
      }),
      codiceFiscale: new TextInput({
        type: 'text',
        size,
        value: this.user?.codiceFiscale,
        required: true,
        readonly: this.user?.codiceFiscale ? true : false,
        label: 'PROFILE_FORM_INPUT_FISCAL_CODE_LABEL',
        placeholder: 'PROFILE_FORM_INPUT_FISCAL_CODE_PLACEHOLDER',
        validationStatus: [
          ValidationStatus.ERROR.REQUIRED,
          ValidationStatus.WARNING((control) => control.hasError('pattern'), {
            text: this.i18n.translate('VALIDATIONS_PATTERN_CF'),
          }),
        ],
        pattern: this.fiscalCodeValidationPattern,
      }),
      dataNascita: new DateInput({
        size,
        value: this.user?.dataNascita
          ? formatDate(this.user?.dataNascita, 'yyyy-MM-dd', 'it-IT')
          : null,
        required: true,
        readonly: this.user?.dataNascita ? true : false,
        label: 'PROFILE_FORM_INPUT_BIRTH_DATE',
        placeholder: 'PROFILE_FORM_INPUT_BIRTH_DATE',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
      }),
      sesso: new AutocompleteInput({
        size,
        value: this.user?.sesso,
        required: true,
        readonly: this.user?.sesso ? true : false,
        label: 'PROFILE_FORM_INPUT_SEX',
        placeholder: 'PROFILE_FORM_INPUT_SEX',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: of([
          {
            label: this.i18n.translate('PROFILE_MALE_SEX_OPTION'),
            value: 'M',
          },
          {
            label: this.i18n.translate('PROFILE_FEMALE_SEX_OPTION'),
            value: 'F',
          },
        ]),
      }),
      noop: new NopInput({ size }),

      nazioneNascitaUuid: new AutocompleteInput({
        size,
        value: this.user?.nazioneNascitaUuid,
        required: true,
        readonly: this.user?.nazioneNascitaUuid ? true : false,
        label: 'PROFILE_FORM_INPUT_BIRTH_STATE',
        placeholder: 'PROFILE_FORM_INPUT_BIRTH_STATE',
        validationStatus: [ValidationStatus.ERROR.REQUIRED],
        options: of(this.nazioni) /* .pipe(
          tap((nations) => {
            const value = this.form.get('nazioneNascitaUuid').value;
            if (value === this.uuidItalia) {
              this._setProvincesAndCitiesOptions(
                'provinciaNascitaUuid',
                'comuneNascitaUuid',
                value
              );
            }
          })
        ) */,
        valueChange: (value: string) => {
          this._checkFiscalCodeMandatory(value);
          if (!value || value === this.uuidItalia) {
            if (this.form.get('cittaEsteraNascita'))
              this.handleItalia('nascita');

            this.logger.log(
              this,
              'nazioneNascitaUuid:valueChange',
              `value: ${value}`
            );
            this.form.get('provinciaNascitaUuid').reset();
            this.form.get('comuneNascitaUuid').reset();
            this.form.get('provinciaNascitaUuid').updateValueAndValidity();
            this.form.get('comuneNascitaUuid').updateValueAndValidity();
            this.form.get('provinciaNascitaUuid').markAllAsTouched();
            this.form.get('comuneNascitaUuid').markAllAsTouched();
            this._setProvincesAndCitiesOptions(
              'provinciaNascitaUuid',
              'comuneNascitaUuid',
              value
            );
          } else {
            if (!this.form.get('cittaEsteraNascita')) {
              this.handleEstero('nascita');
            }
          }
        },
      }),

      ...this.fieldsNascita,

      ...(this.user?.tipoRuolo === 'FO' && {
        nazioneResidenzaUuid: new AutocompleteInput({
          size,
          value: this.user?.nazioneResidenzaUuid,
          label: 'PROFILE_FORM_INPUT_RESIDENTIAL_STATE',
          placeholder: 'PROFILE_FORM_INPUT_RESIDENTIAL_STATE',
          options: of(this.nazioni) /* .pipe(
            tap(() => {
              const value = this.form.get('nazioneResidenzaUuid').value;
              if (value) {
                this._setProvincesAndCitiesOptions(
                  'provinciaResidenzaUuid',
                  'comuneResidenzaUuid',
                  value
                );
              }
            })
          ) */,
          valueChange: (value: string) => {
            if (!value || value === this.uuidItalia) {
              if (this.form.get('cittaEsteraResidenza'))
                this.handleItalia('residenza');

              this.logger.log(
                this,
                'nazioneResidenzaUuid:valueChange',
                `value: ${value}`
              );
              this.form.get('provinciaResidenzaUuid').reset();
              this.form.get('comuneResidenzaUuid').reset();
              this._setProvincesAndCitiesOptions(
                'provinciaResidenzaUuid',
                'comuneResidenzaUuid',
                value
              );
            } else {
              if (!this.form.get('cittaEsteraResidenza')) {
                this.handleEstero('residenza');
              }
            }
          },
        }),

        ...this.fieldsResidenza,

        indirizzo: new TextInput({
          type: 'text',
          size,
          value: this.user?.indirizzo,
          label: 'PROFILE_FORM_INPUT_ADDRESS_LABEL',
          placeholder: 'PROFILE_FORM_INPUT_ADDRESS_PLACEHOLDER',
        }),

        cap: new TextInput({
          type: 'text',
          size,
          value: this.user?.cap,
          label: 'PROFILE_FORM_INPUT_POSTAL_CODE_LABEL',
          placeholder: 'PROFILE_FORM_INPUT_POSTAL_CODE_PLACEHOLDER',
          pattern: '[0-9]{5}',
          validationStatus: [
            ValidationStatus.WARNING((control) => control.hasError('pattern'), {
              text: this.i18n.translate('VALIDATIONS_PATTERN_CAP'),
            }),
          ],
        }),
        telefonoFisso: new TextInput({
          type: 'tel',
          size: '12|12|6|4|4',
          pattern: '^([+|0][0-9]{1,3})?[0-9]{3,14}$',
          readonly:
            !this.isSignup && this.readonlyFields.includes('telefonoFisso'),
          validationStatus: [
            ValidationStatus.WARNING((control) => control.hasError('pattern'), {
              text: 'USER_PROFILE_PHONE_ERROR',
            }),
          ],
          value: this.user?.telefonoFisso,
          label: 'USER_PROFILE_PHONE_LABEL',
          placeholder: 'USER_PROFILE_PHONE_PLACEHOLDER',
        }),
        telefonoCell: new TextInput({
          type: 'tel',
          size: '12|12|6|4|4',
          value: this.user?.telefonoCell,
          readonly:
            !this.isSignup && this.readonlyFields.includes('telefonoCell'),
          pattern: '^([+|0][0-9]{1,3})?[0-9]{3,14}$',
          validationStatus: [
            ValidationStatus.WARNING((control) => control.hasError('pattern'), {
              text: 'USER_PROFILE_MOBILE_ERROR',
            }),
          ],
          label: 'USER_PROFILE_MOBILE_PHONE_LABEL',
          placeholder: 'USER_PROFILE_MOBILE_PHONE_PLACEHOLDER',
        }),
      }),

      ...((this.isSignup && this.fieldsMail.email.value) ||
        (!this.isSignup &&
          this.stato === 'TO_BE_COMPLETED' &&
          this.fieldsMail.email.value && {
            email: this.fieldsMail.email,
          })),

      ...((this.isSignup || this.stato === 'TO_BE_COMPLETED') && {
        pec: this.fieldsMail.pec,
      }),

      ...((this.isSignup || this.stato === 'TO_BE_COMPLETED') && {
        datiPersonali: new CheckboxInput({
          label: 'PROFILE_FORM_INPUT_TRATTAMENTO_LABEL',
          value: false,
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
        }),
      }),

      ...(this.user?.tipoRuolo !== 'FO' && {
        notificaControlloAccessi: new CheckboxInput({
          label: 'PROFILE_FORM_INPUT_NOTIFICHE_LABEL',
          value: this.user?.soggettoFisicoEnte.notificaControlloAccessi,
        }),
      }),
    };
  }

  private handleItalia(type: 'residenza' | 'nascita') {
    const name = type[0].toUpperCase() + type.slice(1);
    const nameCitta = `cittaEstera${name}`;
    this.form.removeControl(nameCitta);
    this.form.removeControl(`nope${name}`);
    const comune = this[`fields${name}`][`comune${name}Uuid`];
    const provincia = this[`fields${name}`][`provincia${name}Uuid`];
    this.form.addControlAfter(
      `provincia${name}Uuid`,
      provincia,
      `nazione${name}Uuid`
    );
    this.form.addControlAfter(
      `comune${name}Uuid`,
      comune,
      `provincia${name}Uuid`
    );
  }

  private handleEstero(type: 'residenza' | 'nascita') {
    const name = type[0].toUpperCase() + type.slice(1);
    const nameCittaField = `cittaEstera${name}`;
    this.form.removeControl(`provincia${name}Uuid`);
    this.form.removeControl(`comune${name}Uuid`);
    const cittaEstera = this[`fieldsEstera`][`cittaEstera${name}`];
    this.form.addControlAfter(
      `cittaEstera${name}`,
      cittaEstera,
      `nazione${name}Uuid`
    );
    this.form.get(`cittaEstera${name}`).markAllAsTouched();
    this.form.addControlAfter(
      `nope${name}`,
      new NopInput({
        size: this.size,
      }),
      nameCittaField
    );
  }
}
