import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@eng-ds/translate';
import { AuthService } from 'src/app/core/services/auth.service';

declare const Adsp: any;
declare const AgidWebComponents: any;
@Component({
  template: '',
})
export class AuthComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private i18n: I18nService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // workaround for loop infinito di redirect
    setTimeout(() => {
      this.authService.tryLogin();
    }, 100);
  }
  error_description = null;
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.error_description = params['error_description'];
    });
    if (this.error_description) {
      AgidWebComponents.notifications.error(
        this.i18n.translate('UTILS_TOASTR_ERROR_GENERIC_TITLE'),
        this.i18n.translate('ERRORE_LOGIN'),
        { position: 'right' }
      );
      this.router.navigate(['']);
    }
  }
}
