import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';
import { FormModule } from './form';
import { AssetUrlPipe } from './pipes';
import { ColorStatusCheckDirective, ColorStatusDirective } from './directives';

@NgModule({
  imports: [TranslateModule.forChild(), FormModule],
  exports: [CommonModule, AssetUrlPipe, TranslateModule, FormModule],
  declarations: [AssetUrlPipe, ColorStatusDirective, ColorStatusCheckDirective],
})
export class SharedModule {}
